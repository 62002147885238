import {EventSidebar} from 'organization/Event/Sidebar'
import DashboardPage from 'organization/theme/DashboardPage'
import React from 'react'

export default function EventPage(props: {
  children: React.ReactElement
  modals?: React.ReactElement
}) {
  return (
    <DashboardPage modals={props.modals} sidebar={<EventSidebar />}>
      {props.children}
    </DashboardPage>
  )
}

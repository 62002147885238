import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import YouTubeIcon from '@mui/icons-material/YouTube'
import ReactPlayer from 'react-player/lazy'
import styled, {CSSProperties} from 'styled-components'

export const VideoTypeId = 'block-video'

export function Video(props: {
  block: VideoConfigType
  section: Section
  setEditMode: () => void
  key: string | number
  editMode: boolean
}) {
  const {block, setEditMode, editMode} = props

  const NoVideo = () => {
    return (
      <PreviewHolderDiv
        style={{
          display: 'flex',
          minHeight: '360px',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px solid',
          width: '100%',
        }}
      >
        <YouTubeIcon />
        <span>Видео</span>
      </PreviewHolderDiv>
    )
  }

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <>
        {block.url && editMode && <NoVideo />}
        {block.url && !editMode && (
          <ReactPlayer controls={block.controls} url={block.url} />
        )}
        {!block.url && <NoVideo />}
      </>
    </DefaultBlockHolder>
  )
}

export function PreviewVideo() {
  return (
    <PreviewHolderDiv>
      <YouTubeIcon />
      <span>Видео</span>
    </PreviewHolderDiv>
  )
}

export type VideoConfigType = Block &
  DefaultBlockConfigType & {
    url: string
    controls: boolean
  }

export function newVideo(): VideoConfigType {
  return {
    ...DefaultBlockConfigValues,
    id: uuidv4(),
    type: VideoTypeId,
    url: '',
    controls: false,
  }
}

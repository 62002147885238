import {publicImageUrl} from 'organization/ImageSelector'
import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import styled from 'styled-components'

export const ImageBlockTypeId = 'block-image'

export function ImageBlock(props: {
  block: ImageBlockConfigType
  section: Section
  setEditMode: () => void
  key: string | number
}) {
  const {block, setEditMode} = props

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <>
        {block.image && (
          <ResponsiveImage
            mobileWidth={block.mobileWidth}
            width={block.width}
            src={publicImageUrl(block.image)}
            alt="event img"
          />
        )}
        {!block.image && (
          <NoImage>
            <AddPhotoAlternateIcon />
          </NoImage>
        )}
      </>
    </DefaultBlockHolder>
  )
}

export function PreviewImageBlock() {
  return (
    <PreviewHolderDiv>
      <AddPhotoAlternateIcon /> <span>Изображение</span>
    </PreviewHolderDiv>
  )
}

export type ImageBlockConfigType = Block &
  DefaultBlockConfigType & {
    image: string | undefined
    width: number
    mobileWidth: number
  }

export function newImageBlock(): ImageBlockConfigType {
  return {
    ...DefaultBlockConfigValues,
    id: uuidv4(),
    type: ImageBlockTypeId,
    image: undefined,
    width: 50,
    mobileWidth: 100
  }
}

const NoImage = styled.div`
  width: 100%;
  text-align: center;
  & > svg {
    font-size: 40px;
    color: #333333;
  }
`

const ResponsiveImage = styled.img<{width: number, mobileWidth: number}>`
  width: ${(props) => props.width}%; 

   @container (max-width: 991px) {
    width: ${(props) => props.mobileWidth}%;
  } 
`
     
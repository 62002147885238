import {UpdatePasswordData} from 'attendee/Auth/Interfaces/update_password'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {ErrorMsg} from 'attendee/Auth/Register'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {password_update_schema} from 'utils/validation-schema'

export default function PasswordUpdate() {
  const {updatePassword} = useAuth()
  const [isBtnDisable, setBtnDisable] = useState(false)

  const {handleSubmit, handleBlur, handleChange, values, errors, touched} =
    useFormik({
      initialValues: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      validationSchema: password_update_schema,
      onSubmit: async (values: UpdatePasswordData) => {
        setBtnDisable(true)
        const registerResult = await updatePassword(values)

        if (registerResult === true) {
          toast.success('Паролата е сменена успешно')
        }

        setBtnDisable(false)
      },
    })

  return (
    <form onSubmit={handleSubmit}>
      <div className="singel__input-field mb-15">
        <label className="input__field-text">Вашата парола</label>
        <input
          type="password"
          name={'old_password'}
          value={values.old_password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.old_password && <ErrorMsg errors={errors.old_password} />}
      </div>
      <div className="singel__input-field mb-15">
        <label className="input__field-text">Нова парола</label>
        <input
          type="password"
          placeholder=""
          name={'password'}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.password && <ErrorMsg errors={errors.password} />}
      </div>
      <div className="singel__input-field mb-15">
        <label className="input__field-text">Повторете парола</label>
        <input
          type="password"
          placeholder=""
          name={'password_confirmation'}
          value={values.password_confirmation}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.password_confirmation && (
          <ErrorMsg errors={errors.password_confirmation} />
        )}
      </div>

      <div className="input__btn-wrapper">
        <button
          className={
            isBtnDisable
              ? 'disableBtnStyle w-100 mb-20'
              : 'input__btn w-100 mb-20'
          }
          type="submit"
          disabled={isBtnDisable}
        >
          Промяна на парола
        </button>
      </div>
    </form>
  )
}

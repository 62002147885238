import {Attendee} from 'attendee/models/attendee'
import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'
import {
  useRouteOrganizationAtendantId,
  useRouteOrganizationEventId,
} from 'utils/lib/url'

export type JoinLink = {
  join_link: string
}

export type Attendant = {
  id: number
  attendee: Attendee
  created_at: Date
  price: number
  join_link?: JoinLink
}

export interface AttendantContextProps {
  attendant: Attendant | null
}

export const AttendantContext = React.createContext<
  AttendantContextProps | undefined
>(undefined)

export default function AttendantProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [attendant, setAttendant] = useState<Attendant | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const eventId = useRouteOrganizationEventId()
  const attendantId = useRouteOrganizationAtendantId()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(
        `api/organization/event/${eventId}/attendant/${attendantId}`,
      )
      setAttendant(response)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  return (
    <AttendantContext.Provider
      value={{
        attendant,
      }}
    >
      {loading && <FullPageLoader />}
      {!loading && props.children}
    </AttendantContext.Provider>
  )
}

export function useAttendant() {
  const context = React.useContext(AttendantContext)
  if (context === undefined) {
    throw new Error('useAttendant must be used within AttendantProvider')
  }
  return context
}

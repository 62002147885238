import React from 'react'
import Form from 'react-bootstrap/Form'

export function SliderInput(props: {
  value: number
  setFieldValue: (field: string, value: number) => void
  label: string
  name: string
  max: number
  min: number
  step: number
}) {
  const {value, setFieldValue, name, max, min, step} = props

  const handleOnChange = (value: number) => {
    setFieldValue(name, value)
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>
        {props.label} - {value}
      </Form.Label>
      <Form.Range
        onChange={(e) => {
          handleOnChange(e.target.value as unknown as number)
        }}
        value={value}
        max={max}
        min={min}
        step={step}
      />
    </Form.Group>
  )
}

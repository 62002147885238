import overlay_img from 'images/loginb2.webp'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {password_reset_schema} from 'utils/validation-schema'

import {routes} from 'routes/routes'

import {useQueryParams} from 'utils/lib/url'
import {toast} from 'react-toastify'
import {useOrganizationAuth} from 'organization/Auth/Providers/OrganizationAuthProvider'
import {PasswordRecoverUpdateData} from 'organization/Auth/Interfaces/password_recover'

export function ResetPassword() {
  const {token, email} = useQueryParams()

  const [isBtnDisable, setBtnDisable] = useState(false)

  const {passwordReset} = useOrganizationAuth()

  const {handleSubmit, handleBlur, handleChange, values, errors, touched} =
    useFormik({
      initialValues: {
        password: '',
        password_confirmation: '',
      },
      validationSchema: password_reset_schema,
      onSubmit: async (values: PasswordRecoverUpdateData) => {
        setBtnDisable(true)
        const resetResult = await passwordReset(
          values,
          token ?? '',
          email ?? '',
        )

        if (resetResult === true) {
          toast.success('Паролата успешно променена')
        }

        setBtnDisable(false)
      },
    })

  return (
    <>
      <main className={`body-area bd-theme-light`}>
        <section className="signin__area">
          <div className="sign__main-wrapper">
            <div className="sign__left">
              <div className="sign__header">
                <div className="sign__logo">
                  <Link to="/">
                    <img
                      style={{width: '150px', height: 'auto'}}
                      className="logo-black"
                      src={process.env.PUBLIC_URL + '/logo-dark.svg'}
                      alt="logoblack"
                    />
                  </Link>
                </div>
                <div className="sign__link custome_link">
                  <Link className="sign__link-active" to={routes.login}>
                    Вход
                  </Link>
                  <Link className="sign__link-text" to={routes.register}>
                    Регистрация
                  </Link>
                </div>
              </div>
              <div className="sign__center-wrapper text-center mt-90">
                <div className="sign__title-wrapper mb-40">
                  <h3 className="sign__title">Промяна на парола</h3>
                  <p>Въведете новата си парола</p>
                </div>
                <div className="sign__input-form text-center">
                  <form onSubmit={handleSubmit}>
                    <div className="sign__input mb-32">
                      <input
                        type="password"
                        placeholder="Нова парола"
                        name="password"
                        defaultValue={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <span>
                        {' '}
                        <i className="flaticon-password"></i>
                      </span>{' '}
                      <br />
                      {touched.password && (
                        <ErrorMsg errors={errors.password} />
                      )}
                    </div>

                    <div className="sign__input mb-32">
                      <input
                        type="password"
                        placeholder="Повторете новата парола"
                        name="password_confirmation"
                        defaultValue={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <span>
                        {' '}
                        <i className="flaticon-password"></i>
                      </span>{' '}
                      <br />
                      {touched.password_confirmation && (
                        <ErrorMsg errors={errors.password_confirmation} />
                      )}
                    </div>

                    <div className="sing__button mb-20">
                      <button
                        disabled={isBtnDisable}
                        className={
                          isBtnDisable
                            ? 'disableBtnStyle w-100 mb-20'
                            : 'input__btn w-100 mb-20'
                        }
                        type="submit"
                      >
                        Промяна
                      </button>
                    </div>
                  </form>
                  <div className="if__account mt-85">
                    <p>
                      Нямаш профил?
                      <Link to={routes.register}>
                        <br /> Регистрация
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sign__right">
              <div
                className="sign__input-thumb"
                style={{
                  backgroundImage: `url(${overlay_img})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }}
              ></div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

const ErrorMsg = ({errors}: any) => {
  return (
    <>
      <p className="error-msg" style={{color: 'red'}}>
        {errors}
      </p>
    </>
  )
}

import React from 'react'
import {Link} from 'react-router-dom'
import {useCollapse} from 'react-collapsed'
import {useOrganizationRoutes} from 'organization/OrganizationRoutes'

const EventMenu = () => {
  const routes = useOrganizationRoutes()

  const {getCollapseProps, getToggleProps, isExpanded, setExpanded} =
    useCollapse()
  const handleCollapse = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }
  return (
    <li className={isExpanded ? 'mm-active' : ''}>
      <Link
        to="#"
        className="has-arrow"
        {...getToggleProps({onClick: handleCollapse})}
      >
        <i className="flaticon-reminder"></i>
        <span className="nav-text">Събития</span>
      </Link>
      <ul {...getCollapseProps()}>
        <li>
          <Link to={routes.events}>Моите събитие</Link>
        </li>
        <li>
          <Link to={routes.event.create.root}>Създай събитие</Link>
        </li>
      </ul>
    </li>
  )
}

export default EventMenu

import {Pagination} from '@mui/material'
import {AddModal} from 'organization/Event/Attendants/AddModal'
import {ListAttendant} from 'organization/Event/Attendants/ListAttendant'
import EventPage from 'organization/Event/EventPage'
import {useEventAttendants} from 'organization/Event/Attendants/EventAttendantsProvider'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React, {useState} from 'react'
import styled from 'styled-components'

export const Attendants = () => {
  const {attendants, totalPages, page, changePage, searchAtendant} =
    useEventAttendants()
  const [showModal, setShowModal] = useState<boolean>(false)

  const toggleShowModal = () => {
    setShowModal((curr: boolean) => !curr)
  }

  const [timer, setTimer] = useState<any>(null)

  function delayedSearch(change: string) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        searchAtendant(change)
      }, 500),
    )
  }

  return (
    <EventPage modals={<AddModal show={showModal} toggle={toggleShowModal} />}>
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Участници" />

          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper">
                <div className="attendant__wrapper mb-35">
                  <ButtonsHolder>
                    <div className="singel__input-field mr-15">
                      <input
                        type="email"
                        name="email"
                        placeholder="Търсене по имейл"
                        onChange={(e) => {
                          delayedSearch(e.target.value)
                        }}
                      />
                    </div>

                    <button className="input__btn" onClick={toggleShowModal}>
                      Ръчно добавяне
                    </button>
                  </ButtonsHolder>

                  <table>
                    <thead>
                      <tr>
                        <th>Номер на билет</th>
                        <th>Име</th>
                        <th>Фамилия</th>
                        <th>Имейл</th>
                        <th>Статус</th>
                        <th>{/* ACTIONS */}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendants.length === 0 && (
                        <tr>
                          <td colSpan={6}>Няма регистрирани участници</td>
                        </tr>
                      )}
                      {attendants &&
                        attendants.map((attendant, index) => (
                          <ListAttendant attendant={attendant} index={index} />
                        ))}
                    </tbody>
                  </table>

                  <PaginationHolder>
                    <Pagination
                      count={totalPages}
                      shape="rounded"
                      page={page}
                      onChange={changePage}
                    />
                  </PaginationHolder>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

const PaginationHolder = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`

const ButtonsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

import React from 'react'
import Form from 'react-bootstrap/Form'

export function FontInput(props: {
  value: string
  setFieldValue: (field: string, value: string) => void
  label: string
  name: string
}) {
  const {value, setFieldValue, name} = props

  const handleOnChange = (value: string) => {
    setFieldValue(name, value)
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>
      <Form.Select
        onChange={(e) => {
          handleOnChange(e.target.value)
        }}
        className="form-control"
        value={value}
      >
        <option value="Roboto">Roboto</option>
        <option value="Rubik">Rubik</option>
        <option value="Caveat">Caveat</option>
        <option value="Lobster">Lobster</option>
        <option value="Comfortaa">Comfortaa</option>
        <option value="Pacifico">Pacifico</option>
        <option value="PT Sans">PT Sans</option>
        <option value="IBM Plex Mono">IBM Plex Mono</option>
        <option value="Raleway">Raleway</option>
        <option value="Open Sans">Open Sans</option>
      </Form.Select>
    </Form.Group>
  )
}

import React from 'react'
import styled from 'styled-components'
import {BlockDrop} from 'TemplateEditor/Layout/Sections/BlockDrop'
import {DefaultSectionHolder} from 'TemplateEditor/Layout/Sections/DefaultSectionHolder'
import {
  defaultSectionConfig,
  SectionProps,
} from 'TemplateEditor/Layout/Sections/Section'
import {
  PreviewSectionDiv,
  PreviewSectionHolderDiv,
} from 'TemplateEditor/Layout/Sections/Types/Section1'
import {Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'

export const section1by1TypeId = 'section-1-1'

export function Section1by1(props: SectionProps) {
  const {section, sectionIndex, key} = props

  return (
    <DefaultSectionHolder section={section} key={key}>
      <>
        <ResponsiveSections >
          <BlockDrop section={section} index={1} sectionIndex={sectionIndex} />
        </ResponsiveSections>
        <ResponsiveSections >
          <BlockDrop section={section} index={2} sectionIndex={sectionIndex} />
        </ResponsiveSections>
      </>
    </DefaultSectionHolder>
  )
}

export function PreviewSection1by1() {
  return (
    <PreviewSectionHolderDiv>
      <PreviewSectionDiv style={{width: '50%'}}>50%</PreviewSectionDiv>
      <PreviewSectionDiv style={{width: '50%'}}>50%</PreviewSectionDiv>
    </PreviewSectionHolderDiv>
  )
}

export function newSection1by1(): Section {
  return {
    ...defaultSectionConfig,
    id: uuidv4(),
    type: section1by1TypeId,
    blocks: {},
  }
}

const ResponsiveSections = styled.div`
  width: 50%; 

   @container (max-width: 991px) {
    width: 100%;
  } 
`
import React from 'react'
import styled from 'styled-components'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import AdsClickIcon from '@mui/icons-material/AdsClick'
import {useFunctions} from 'TemplateEditor/FunctionsProvider'

export const ButtonOneTypeId = 'block-button-one'

export function ButtonOne(props: {
  block: ButtonOneConfigType
  section: Section
  setEditMode: () => void
  key: string | number
  editMode: boolean
}) {
  const {block, setEditMode, editMode} = props
  const {joinMeeting} = useFunctions()

  const handleClick = () => {
    if (editMode) return

    if (block.linkType === 'internal') {
      if (block.link === 'main_stage') {
        if (joinMeeting) {
          joinMeeting.join()
        }
      }
    }

    if (block.linkType === 'external') {
      window.open(block.link, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <DefaultButton block={block} onClick={handleClick}>
        {props.block.text}
      </DefaultButton>
    </DefaultBlockHolder>
  )
}

export function PreviewButtonOne() {
  return (
    <PreviewHolderDiv>
      <AdsClickIcon />
      <span>Бутон</span>
    </PreviewHolderDiv>
  )
}

export type ButtonOneConfigType = Block &
  DefaultBlockConfigType & {
    backgroundColor: string
    text: string
    font: string
    textColor: string
    link: string
    linkType: 'internal' | 'external'
  }

export function newButtonOne(): ButtonOneConfigType {
  return {
    ...DefaultBlockConfigValues,
    id: uuidv4(),
    type: ButtonOneTypeId,
    backgroundColor: '#ffffff',
    textColor: '#000000',
    text: 'Бутон',
    font: 'Roboto',
    link: '',
    linkType: 'internal',
  }
}

const DefaultButton = styled.div<{block: ButtonOneConfigType}>`
  background: ${(props) => props.block.backgroundColor};
  color: ${(props) => props.block.textColor};
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.block.font};
  cursor: pointer;
`

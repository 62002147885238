import React, {ReactNode} from 'react'
import Form from 'react-bootstrap/Form'

export function SwitchInput(props: {
  value: boolean
  setFieldValue: (field: string, value: boolean) => void
  label: string
  name: string
  info?: ReactNode
}) {
  const {value, setFieldValue, name} = props

  const handleOnChange = () => {
    setFieldValue(name, !value)
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>
      <Form.Switch
        label={value ? 'Да' : 'Не'}
        onChange={handleOnChange}
        checked={value}
      />

      {props.info}
    </Form.Group>
  )
}

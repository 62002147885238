import moment from 'moment'
import {useOrganizationAuth} from 'organization/Auth/Providers/OrganizationAuthProvider'
import {useEventEarning} from 'organization/Event/Earning/EventEarningProvider'
import React, {useEffect} from 'react'
import styled from 'styled-components'

export default function WithdrawModal(props: {
  isOpen: boolean
  toggleOpen: () => void
}) {
  const {isOpen, toggleOpen} = props
  const {user} = useOrganizationAuth()
  const {createWithdrawRequest, withdrawalRequest, getWithdrawRequest} =
    useEventEarning()

  useEffect(() => {
    getWithdrawRequest()
  }, [])

  return (
    <>
      <div
        onClick={toggleOpen}
        className={`offcanvas__overlay ${isOpen ? 'overlay-open' : ''}`}
      ></div>
      <section className={`speaker__popup-area ${isOpen ? 'open' : ''}`}>
        <div className="popup__wrapper">
          <div className="popup__title-wrapper">
            <h3 className="popup__title">Изтегляне на печалба</h3>
          </div>
          <div className="popup__input-wrapper">
            <h3 className="mb-40">
              Автоматичното изтегляне на средства е в процес на разработка.
            </h3>
            <h4 className="mb-60">
              Направете <b>заявка за изтегляне</b> и наш служител ще се свърже с
              Вас на имейл <b>{user?.email}</b> до един работен ден.
            </h4>

            {withdrawalRequest && (
              <WithdrawRequestHolder>
                Вашата заявка: <br />
                <br /> Номер: <b>{withdrawalRequest.id}</b>
                <br />
                Направена на:{' '}
                <b>
                  {moment(withdrawalRequest.created_at).format(
                    'DD MMMM YYYY HH:mm',
                  )}{' '}
                </b>
                <br />
                Имейл: <b>{user?.email}</b>
              </WithdrawRequestHolder>
            )}

            {!withdrawalRequest && (
              <button
                onClick={createWithdrawRequest}
                className="input__btn w-100"
              >
                Заяви изтегляне
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

const WithdrawRequestHolder = styled.div`
  background: rgba(69, 167, 79, 0.1);
  font-size: 1.5rem;
  padding: 2rem;
  line-height: 2rem;
`

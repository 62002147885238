import React from 'react'
import {Login} from 'attendee/Auth/Login'
import {Route, Switch} from 'react-router-dom'
import {routes} from 'routes/routes'
import {Index} from 'attendee'
import {Register} from 'attendee/Auth/Register'
import AttendeeEventsListProvider from 'attendee/providers/AttendeeEventsListProvider'
import {Billing} from 'attendee/Billing'
import {Tickets} from 'attendee/Tickets'
import {AuthReqired} from 'routes/AuthReqired'
import {Profile} from 'attendee/Auth/Profile'
import {PasswordRecover} from 'attendee/Auth/PasswordRecover'
import {GoogleCallback} from 'attendee/Auth/GoogleCallback'
import {Contacts} from 'attendee/Contacts'
import {Info} from 'attendee/Info'
import {Search} from 'attendee/Search'

export function AttendeeRoutes() {
  return (
    <>
      <Switch>
        <Route path={routes.login}>
          <Login />
        </Route>
        <Route path={routes.register}>
          <Register />
        </Route>
        <Route path={routes.auth.google}>
          <GoogleCallback />
        </Route>
        <Route path={routes.passwordRecover}>
          <PasswordRecover />
        </Route>
        <Route path={routes.profile}>
          <Profile />
        </Route>
        <Route path={routes.billing}>
          <Billing />
        </Route>
        <Route path={routes.tickets}>
          <AuthReqired>
            <Tickets />
          </AuthReqired>
        </Route>
        <Route path={routes.info}>
          <Info />
        </Route>
        <Route path={routes.contacts}>
          <Contacts />
        </Route>
        <Route path={routes.search}>
          <AttendeeEventsListProvider>
            <Search />
          </AttendeeEventsListProvider>
        </Route>
        <Route path={routes.index}>
          <AttendeeEventsListProvider>
            <Index />
          </AttendeeEventsListProvider>
        </Route>
      </Switch>
    </>
  )
}

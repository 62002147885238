import React, {useEffect, useState} from 'react'
import {organizationApiClient} from 'organization/organizationApiClient'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import {useRouteOrganizationEventId} from 'utils/lib/url'
import {Image, publicImageUrl} from 'organization/ImageSelector'

export function ImagesList(props: {onSelect: (image: Image) => void}) {
  const {images, get} = useGetImage()
  const saveImage = useSaveImage()
  const removeImage = useRemoveImage()

  const saveImages = async (files: any) => {
    for (let i = 0; i < files.length; i++) {
      await saveImage(files[i])
    }

    get()
  }

  const handleRemove = async (imageId: number) => {
    await removeImage(imageId)
    get()
  }

  return (
    <div
      className="event__update-file"
      style={{gridTemplateColumns: 'repeat(5, 1fr)'}}
    >
      <div className="event__update-thumb" style={{height: '100%'}}>
        <div
          className="box__input"
          style={{
            background: 'var(--clr-gradient-14)',
            borderRadius: '6px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <input
            type="file"
            name="files[]"
            id="file"
            className="box__file"
            data-multiple-caption="{count} files selected"
            multiple
            onChange={(e) => {
              if (e.currentTarget.files) {
                saveImages(e.currentTarget.files)
              }
            }}
          />
          <label
            className="input__field-text"
            htmlFor="file"
            style={{
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{color: '#fff'}}>
              <i className="fa-regular fa-plus"></i>
            </span>
            <span style={{color: '#fff'}}>Добави изображение</span>
          </label>
        </div>
      </div>

      {images &&
        images.map((image) => (
          <div className="event__update-thumb">
            <img
              src={publicImageUrl(image.url)}
              style={{width: '100%', height: 'auto', cursor: 'pointer'}}
              alt="image"
              onClick={() => {
                props.onSelect(image)
              }}
            />
            <span
              className="update__thumb-close"
              onClick={() => {
                handleRemove(image.id)
              }}
            >
              <i className="fa-regular fa-xmark"></i>
            </span>
          </div>
        ))}
    </div>
  )
}

function useGetImage() {
  const [images, setImages] = useState<Image[]>([])
  const apiClient = organizationApiClient()
  const {event} = useOrganizationEvent()
  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    const response = await apiClient.get(`api/event/${event?.id}/images`)
    setImages(response)
  }

  return {
    images,
    get,
  }
}

function useSaveImage() {
  const apiClient = organizationApiClient()
  const eventId = useRouteOrganizationEventId()

  async function saveImage(image: File) {
    const formData = new FormData()
    formData.append('file', image)

    return apiClient.post(`api/event/${eventId}/image`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  return saveImage
}

function useRemoveImage() {
  const apiClient = organizationApiClient()
  const eventId = useRouteOrganizationEventId()

  async function removeImage(imageId: Number) {
    return apiClient.post(`api/event/${eventId}/image/${imageId}`)
  }

  return removeImage
}

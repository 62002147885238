import usePayment from 'attendee/Billing/Payments/usePayment'
import {EventModel} from 'event/models/Event'
import {useHistory} from 'react-router-dom'
import {routes} from 'routes/routes'

export default function useTicketPayment(event: EventModel | null) {
  const {newPayment} = usePayment()
  const navigate = useHistory()

  const onSuccess = () => {
    navigate.push(routes.tickets)
  }

  const payment = () => {
    if (!event) return

    return newPayment(
      `api/payment/event/${event.id}/buy-ticket`,
      `api/payment/event/${event.id}/ticket-confirmation`,
      onSuccess,
    )
  }

  return {
    payment: payment(),
  }
}

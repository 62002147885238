import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import CodeIcon from '@mui/icons-material/Code'

export const CodeTypeId = 'block-Code'

export function Code(props: {
  block: CodeConfigType
  section: Section
  setEditMode: () => void
  key: string | number
}) {
  const {block, setEditMode} = props

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <div dangerouslySetInnerHTML={{__html: block.value}} />
    </DefaultBlockHolder>
  )
}

export function PreviewCode() {
  return (
    <PreviewHolderDiv>
      <CodeIcon />
      <span>Код</span>
    </PreviewHolderDiv>
  )
}

export type CodeConfigType = Block &
  DefaultBlockConfigType & {
    value: string
  }

export function newCode(): CodeConfigType {
  return {
    ...DefaultBlockConfigValues,
    id: uuidv4(),
    type: CodeTypeId,
    value: '<p>Персонализиран код</p>',
  }
}

import {EventModel} from 'event/models/Event'
import React, {useState} from 'react'
import {DefaultTemplate, Template} from 'TemplateEditor/template'

export interface TemplateContextProps {
  template: Template
  updateTemplate: (template: Template) => void
  setTemplate: (template: Template) => void
  syncedTemplate: boolean
}

export const TemplateContext = React.createContext<
  TemplateContextProps | undefined
>(undefined)

export default function TemplateProvider(props: {
  children: React.ReactElement
  event?: EventModel
  updateEventTemplate?: (template: Template) => void
}) {
  const [syncedTemplate, setSyncedTemplate] = useState<boolean>(false)
  const {event, updateEventTemplate} = props
  const [template, setTemplate] = useState<Template>(
    event?.template ?? DefaultTemplate,
  )

  const pingSyncedTemplate = () => setSyncedTemplate((curr) => !curr)

  const updateTemplate = (updatedTemplate: EventModel['template']) => {
    if (!updateEventTemplate) return

    updateEventTemplate(updatedTemplate)
    setTemplate(updatedTemplate)
    pingSyncedTemplate()
  }

  return (
    <TemplateContext.Provider
      value={{
        template,
        updateTemplate,
        setTemplate,
        syncedTemplate,
      }}
    >
      {props.children}
    </TemplateContext.Provider>
  )
}

export function useTemplate() {
  const context = React.useContext(TemplateContext)
  if (context === undefined) {
    throw new Error('useTemplate must be used within TemplateProvider')
  }
  return context
}


import React from 'react'
import ExampleTemplateItem from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplateItem'
import { ExampleTemplate, useExampleTemplates } from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplatesProvider'

export default function ExampleTemplateModal(props: {
  isOpen: boolean
  toggleOpen: () => void
}) {
  const {isOpen, toggleOpen} = props

  const {exampleTemplates} = useExampleTemplates()

  return (
    <>
      <div
        onClick={toggleOpen}
        className={`offcanvas__overlay ${isOpen ? 'overlay-open' : ''}`}
      ></div>
      <section className={`event__popup-area ${isOpen ? 'open' : ''}`} style={{maxWidth: "80%"}}>
        <div className="popup__wrapper" style={{maxWidth: "100%"}}>
          <div className="popup__title-wrapper">
            <h3 className="popup__title">Примерни дизайни</h3>
          </div>
          <div className="popup__input-wrapper">
           
            {exampleTemplates.map((item: ExampleTemplate ) => {
              return <ExampleTemplateItem exampleTemplate={item} toggleOpen={toggleOpen}/>
            })}
         
          </div>
        </div>
      </section>
    </>
  )
}

import Page from 'attendee/theme/Page'
import React from 'react'
import UnderTitlearrow from 'assets/icons/svg/UnderTitlearrow'
import {EventListItem} from 'attendee/EventListItem'
import {useAttendeeEventsList} from 'attendee/providers/AttendeeEventsListProvider'

export function Search() {
  const {events} = useAttendeeEventsList()

  return (
    <Page>
      <>
        <section className="event__schedule-area dark_light_class pt-55 pb-55">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-7 col-xl-7 col-lg-6">
                <div className="section__title-wrapper is-left mb-50">
                  <span className="section__back-title">ТС</span>
                  <span className="section__subtitle">Намери събитие</span>
                  <h2 className="section__title">
                    Търсене на{' '}
                    <span className="text__highlight">
                      {' '}
                      Събитие
                      <UnderTitlearrow />
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              {events.length > 0 &&
                events.map((event, index) => (
                  <EventListItem event={event} index={index} />
                ))}
              {events.length === 0 && (
                <center>
                  <h4>Няма намерени събития</h4>
                </center>
              )}
            </div>
          </div>
        </section>
      </>
    </Page>
  )
}

import React from 'react'
import Form from 'react-bootstrap/Form'
import {Image, ImageSelector} from 'organization/ImageSelector'

export function ImageInput(props: {
  onSelect: (image: Image | null) => void
  label: string
}) {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{props.label}</Form.Label>
        <ImageSelector onSelect={props.onSelect} />
      </Form.Group>
    </>
  )
}

import {Header} from 'organization/theme/Header'
import Modals from 'organization/theme/Modals'
import DashbordSidebar from 'organization/theme/Sidebar'
import React from 'react'

export default function DashboardPage(props: {
  children: React.ReactElement
  sidebar?: React.JSX.Element
  disableModals?: boolean
  modals?: React.ReactElement
}) {
  return (
    <main className={`body-area bd-theme-light`}>
      {props.disableModals ?? <Modals modals={props.modals} />}
      <div className="page__full-wrapper">
        {props.sidebar ?? <DashbordSidebar />}
        <div className="page__body-wrapper">
          <Header />
          <div className="app__slide-wrapper">{props.children}</div>
        </div>
      </div>
    </main>
  )
}

import {EventModel} from 'event/models/Event'
import {useFormik} from 'formik'
import moment from 'moment'
import EventPage from 'organization/Event/EventPage'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import {SaveEventData} from 'organization/Event/Settings/SaveEvent'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import styled from 'styled-components'

export const Settings = () => {
  const {event, updateEvent, makeEventPublic, endEvent} = useOrganizationEvent()
  const [isBtnDisable, setBtnDisable] = useState(false)

  const {handleSubmit, handleBlur, handleChange, values} = useFormik({
    initialValues: {
      name: event?.name ?? '',
      date: event?.date ?? undefined,
      page_date: event?.page_date ?? undefined,
    },
    onSubmit: async (values: SaveEventData) => {
      setBtnDisable(true)

      const result = await updateEvent(values)

      if (result === true) {
        toast.success('Запазено')
      }

      setBtnDisable(false)
    },
  })

  const handlePublicClick = async () => {
    const loginResult = await makeEventPublic()

    if (loginResult === true) {
      toast.success('Събитието е успешно публикувано')
    }
  }

  const handleEndedClick = async () => {
    const loginResult = await endEvent()

    if (loginResult === true) {
      toast.success('Събитието е спряно')
    }
  }

  return (
    <EventPage>
      <div className="row pl-30 pb-30 pr-30">
        <BreadcrumMenu title="Настройки" />

        <div className="col-xxl-12">
          <div className="create__event-area">
            <div className="body__card-wrapper">
              {/* create event form  */}
              <form onSubmit={handleSubmit}>
                <div className="create__event-main pt-25">
                  <div className="event__left-box">
                    <div className="create__input-wrapper">
                      <div className="singel__input-field mb-15">
                        <label className="input__field-text">
                          Име на събитието
                        </label>

                        <input
                          type="text"
                          name="name"
                          placeholder="Въведете име ..."
                          defaultValue={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>

                      <div className="row g-20">
                        <div className="singel__input-field  mb-15">
                          <label className="input__field-text">
                            Дата и час
                          </label>
                          <input
                            name="date"
                            type="datetime-local"
                            onChange={handleChange}
                            lang="bg-BG"
                            value={values.date?.toString()}
                            required
                          />
                        </div>
                        <small>
                          <i>
                            Датата и часа на събитието не могат да бъдат
                            променени след първият продаден билет *
                          </i>
                        </small>
                      </div>

                      <div className="row g-20 ">
                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Основна страница видима след
                          </label>
                          <input
                            name="page_date"
                            type="datetime-local"
                            onChange={handleChange}
                            lang="bg-BG"
                            value={values.page_date?.toString()}
                            required
                          />
                        </div>
                        <small>
                          <i>
                            От кога страницата от меню "Дизайн" ще бъде видима
                            за участниците.
                          </i>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="event__right-box">
                    <div className="row g-20">
                      <div className="pricing__item mb-30">
                        <div className="pricing__item-header">
                          <div>
                            <span className="pricing__item-title">
                              Публичен достъп
                            </span>
                          </div>
                        </div>
                        <div className="pricing__item-content">
                          <ul>
                            <li>
                              <i className="flaticon-warning-1"></i> Ще бъде
                              добавен в списъка на "Предстоящи събития" в
                              началната страница.
                            </li>
                            <li>
                              <i className="flaticon-warning-1"></i> Ще започне
                              продажбата на билети.
                            </li>
                            <li>
                              <i className="flaticon-warning-1"></i> Участниците
                              ще бъдат известени за добавено ново събитие.
                            </li>
                            <li>
                              <i className="flaticon-warning-1"></i> След
                              активация датата и часът НЕ могат да бъдат
                              променени.
                            </li>
                          </ul>
                        </div>
                        <div className="pricing__btn-wrap">
                          <PublishSection
                            event={event}
                            handlePublicClick={handlePublicClick}
                            handleEndedClick={handleEndedClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isBtnDisable}
                  className={
                    isBtnDisable
                      ? 'disableBtnStyle w-100 mt-30'
                      : 'input__btn w-100 mt-30'
                  }
                >
                  Публикуване
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </EventPage>
  )
}

function PublishSection(props: {
  event: EventModel | null
  handlePublicClick: () => void
  handleEndedClick: () => void
}) {
  const {event, handlePublicClick, handleEndedClick} = props

  if (!event) return null

  if (event?.is_ended) {
    return (
      <>
        <PublishedDate>
          Публикувано на{' '}
          {moment(event.published_at).format('DD MMMM YYYY HH:mm')}
        </PublishedDate>
        <PublishedDate>
          Завършено на {moment(event.ended_at).format('DD MMMM YYYY HH:mm')}
        </PublishedDate>
      </>
    )
  }

  if (event?.is_public) {
    return (
      <>
        <PublishedDate>
          Публикувано на{' '}
          {moment(event.published_at).format('DD MMMM YYYY HH:mm')}
        </PublishedDate>

        <button
          type="button"
          className={'pricing__btn mt-20'}
          onClick={handleEndedClick}
        >
          Завършено събитие
        </button>
      </>
    )
  }

  return (
    <button
      type="button"
      className={'pricing__btn'}
      onClick={handlePublicClick}
    >
      Активация
    </button>
  )
}

const PublishedDate = styled.div`
  text-align: center;
  font-weight: bold;
`

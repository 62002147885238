import {useOrganizationBilling} from 'organization/Billing/Providers/OrganizationBillingProvider'
import {ImagesList} from 'organization/ImageSelector/ImagesList'
import React from 'react'

export default function ImagesModal() {
  const {imagesModalOpen, setImagesModalOpen, imageSelected} =
    useOrganizationBilling()

  const handleOpenModal = () => {
    setImagesModalOpen(!imagesModalOpen)
  }

  return (
    <>
      <div
        onClick={handleOpenModal}
        className={`offcanvas__overlay ${imagesModalOpen ? 'overlay-open' : ''}`}
      ></div>
      <section
        className={`speaker__popup-area ${imagesModalOpen ? 'open' : ''}`}
        style={{maxWidth: '80%'}}
      >
        <div className="popup__wrapper" style={{maxWidth: '100%'}}>
          <div className="popup__title-wrapper">
            <h3 className="popup__title">Вашата галерия</h3>
          </div>
          <div className="popup__input-wrapper">
            {imagesModalOpen && <ImagesList onSelect={imageSelected} />}
          </div>
        </div>
      </section>
    </>
  )
}

import React from 'react'
import {Layout} from 'TemplateEditor/Layout'
import EditorProvider from 'TemplateEditor/EditorProvider'
import TemplateProvider from 'TemplateEditor/TemplateProvider'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import FunctionsProvider from 'TemplateEditor/FunctionsProvider'
require('./templateStyles.css')

export function TemplateEditor() {
  const {event, updateEventTemplate} = useOrganizationEvent()

  if (!event) return null

  return (
    <FunctionsProvider>
      <TemplateProvider event={event} updateEventTemplate={updateEventTemplate}>
        <EditorProvider editMode={true}>
          <Layout />
        </EditorProvider>
      </TemplateProvider>
    </FunctionsProvider>
  )
}

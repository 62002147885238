import {DEFAULT_TICKET_PRICE, DEFAULT_TICKET_TAX} from 'event/ticketPrices'
import {useFormik} from 'formik'
import EventPage from 'organization/Event/EventPage'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import {SaveEventData} from 'organization/Event/Settings/SaveEvent'
import {useOrganizationEventRoutes} from 'organization/OrganizationRoutes'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import styled from 'styled-components'

export const Tickets = () => {
  const {event, updateEvent} = useOrganizationEvent()
  const routes = useOrganizationEventRoutes()

  const [ticketFeatures, setTicketFeatures] = useState<string[]>(
    event && event.ticket_features !== null
      ? JSON.parse(event.ticket_features)
      : [],
  )

  const [isBtnDisable, setBtnDisable] = useState(false)
  const [priceWithoutTax, setPriceWithoutTax] = useState(
    DEFAULT_TICKET_PRICE - DEFAULT_TICKET_TAX,
  )

  const {handleSubmit, handleBlur, handleChange, values, setValues} = useFormik(
    {
      initialValues: {
        price:
          !event?.price || event?.price === 0
            ? DEFAULT_TICKET_PRICE
            : event.price,
        ticket_name: event?.ticket_name,
        ticket_features: event?.ticket_features,
      },
      onSubmit: async (values: SaveEventData) => {
        setBtnDisable(true)

        const result = await updateEvent(values)

        if (result === true) {
          toast.success('Запазено')
        }

        setBtnDisable(false)
      },
    },
  )

  const addTicketFeature = () => {
    setTicketFeatures((features) => [...features, ''])
  }

  const updateFeature = (featureKey: number, value: string) => {
    setTicketFeatures((features) =>
      features.map((feature, index) =>
        index === featureKey ? value : feature,
      ),
    )
  }

  useEffect(() => {
    const updated = ticketFeatures.filter((f) => f)

    setValues({
      ...values,
      ticket_features: JSON.stringify(updated),
    })
  }, [ticketFeatures])

  useEffect(() => {
    setPriceWithoutTax((values.price ?? 0) - DEFAULT_TICKET_TAX)
  }, [values.price])

  return (
    <EventPage>
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Билети" />

          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper">
                <CapacityHolder>
                  <div>
                    <p style={{fontSize: '1.5rem'}}>
                      Капацитет на събитието : <b>{event?.total_seats}</b>
                    </p>
                    <p style={{fontSize: '1rem'}}>
                      Заети места : <b>{event?.occupied_seats}</b>
                    </p>
                    <p style={{fontSize: '1rem'}}>
                      Свободни места :{' '}
                      <b>
                        {(event?.total_seats ?? 0) -
                          (event?.occupied_seats ?? 0)}
                      </b>
                    </p>
                    Може да увеличите капацитета на събитието като закупите
                    съответната добавка.
                  </div>
                  <Link to={routes.addons} className="input__btn w-auto">
                    Закупуване на добавка
                  </Link>
                </CapacityHolder>

                <form onSubmit={handleSubmit}>
                  <div className="create__event-main pt-25">
                    <div className="event__left-box">
                      <div className="create__input-wrapper">
                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Цена на билета:
                          </label>

                          <input
                            type="number"
                            name="price"
                            placeholder="Въведете цена на билета ..."
                            defaultValue={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>
                        <span className="input__alert alert-bg-2 mb-10">
                          Препоръчителна цена: {DEFAULT_TICKET_PRICE}лв
                        </span>

                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Име на билета: <i>/до 10 букви/</i>
                          </label>

                          <input
                            maxLength={10}
                            name="ticket_name"
                            placeholder="Въведете име на билета ..."
                            defaultValue={values.ticket_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Характеристики на билета:
                          </label>
                          {ticketFeatures.map((feature, index) => {
                            return (
                              <input
                                className="mb-20"
                                placeholder="Характеристика ..."
                                onChange={(e) => {
                                  updateFeature(index, e.target.value)
                                }}
                                defaultValue={feature}
                              />
                            )
                          })}
                          <button
                            type="button"
                            className="unfield__input-btn w-100 mb-20"
                            onClick={addTicketFeature}
                          >
                            <i className="fa-regular fa-plus"></i>Добави
                            характеристика
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="event__right-box">
                      <div className="singel__input-field mb-15">
                        <TotalPriceHolder>
                          <div>
                            <i>Вашата печалба</i>
                            {priceWithoutTax} лв.
                          </div>
                          <div>+</div>
                          <div>
                            <i>Такса за участник</i>
                            {DEFAULT_TICKET_TAX} лв.
                          </div>
                          <div>=</div>
                          <TotalPrice>
                            <i>Крайна цена</i>
                            {values.price} лв.
                          </TotalPrice>
                        </TotalPriceHolder>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isBtnDisable}
                    className={
                      isBtnDisable
                        ? 'disableBtnStyle w-100 mt-30'
                        : 'input__btn w-100 mt-30'
                    }
                  >
                    Запазване
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

const TotalPriceHolder = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  padding: 35px 0px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > div > i {
    display: block;
    font-size: 1rem;
    font-style: normal;
  }
`

const TotalPrice = styled.div`
  color: #70d640;
  font-weight: bold;
`

const CapacityHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--clr-border-1);
  padding-bottom: 40px;
  margin-bottom: 20px;
`

import {AddCardForm} from 'attendee/Billing/AddCardForm'
import {PaymentMethods} from 'attendee/Billing/PaymentMethods'
import {Payments} from 'attendee/Billing/Payments'
import Page from 'attendee/theme/Page'
import React from 'react'

export function Billing() {
  return (
    <Page disableModals={true}>
      <section className="event__schedule-area dark_light_class pt-50 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-6">
              <div className="profile__left">
                <div className="padding__left-inner p-relative">
                  <PaymentMethods />
                  <h3>Добави начин на плащане: </h3>
                  <AddCardForm />
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-7 col-lg-6 col-md-6">
              <h3> Плащания: </h3>
              <Payments />
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

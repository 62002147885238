import React, {ReactElement} from 'react'
import {DraggableProvided} from 'react-beautiful-dnd'
import styled from 'styled-components'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {
  newSection1,
  PreviewSection1,
  Section1,
  section1TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section1'
import {
  newSection1by1,
  PreviewSection1by1,
  Section1by1,
  section1by1TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section1by1'
import {
  newSection1by2,
  PreviewSection1by2,
  Section1by2,
  section1by2TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section1by2'
import {
  newSection2by1,
  PreviewSection2by1,
  Section2by1,
  section2by1TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section2by1'
import {Section as SectionData} from 'TemplateEditor/template'

export type SectionProps = {
  section: SectionData
  sectionIndex: number
  key: number
}

export type ListSectionType = {
  typeId: string
  component: (props: any) => ReactElement
  preview: () => ReactElement
  new: () => SectionData
}

export const ListSections: ListSectionType[] = [
  {
    typeId: section1TypeId,
    component: Section1,
    preview: PreviewSection1,
    new: newSection1,
  },
  {
    typeId: section1by1TypeId,
    component: Section1by1,
    preview: PreviewSection1by1,
    new: newSection1by1,
  },
  {
    typeId: section1by2TypeId,
    component: Section1by2,
    preview: PreviewSection1by2,
    new: newSection1by2,
  },
  {
    typeId: section2by1TypeId,
    component: Section2by1,
    preview: PreviewSection2by1,
    new: newSection2by1,
  },
]

export const defaultSectionConfig = {
  horizontalPadding: 0,
  verticalPadding: 0,
  background: '#ffffff',
  borderColor: null,
  borderWidth: 0,
  borderRadius: 0,
  blocks: {},
  revertMobile: false
}

export function Section(props: {
  section: SectionData
  sectionIndex: number
  provided?: DraggableProvided
}) {
  const {section, sectionIndex, provided} = props
  const {editMode, setEditElement} = useEditor()

  const handleSetEditMode = () => {
    if (!editMode) return

    setEditElement(`sections.${sectionIndex}`)
  }

  return (
    <SectionHolder
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      ref={provided?.innerRef}
    >
      {editMode && (
        <EditButton onClick={handleSetEditMode}>
          <i className="fa-light fa-edit"></i>
        </EditButton>
      )}

      {ListSections.map((listSection) => {
        if (section.type !== listSection.typeId) return null

        return listSection.component({
          sectionIndex: sectionIndex,
          section: section,
          key: sectionIndex,
        })
      })}
    </SectionHolder>
  )
}

const EditButton = styled.div`
  position: absolute;
  background: red;
  padding: 0px 10px;
  color: white;
  display: none;
  cursor: pointer;
  right: 0;
  font-size: 18px;
  border-bottom-left-radius: 4px;
`

const SectionHolder = styled.div`
  &:hover ${EditButton} {
    display: inline;
  }
`

import React from 'react'
import {Link} from 'react-router-dom'
import small_logo from 'assets/img/logo/logo-small.svg'
import {routes} from 'routes/routes'
import EventMenu from 'organization/theme/Sidebar/EventMenu'

export const Menu = () => {
  return (
    <>
      <div className="logo-details">
        <span>
          <Link to={routes.organization.list}>
            <img
              style={{width: '100%', height: '100%', maxHeight: '50px'}}
              className="logo__white"
              src={small_logo}
              alt="logo not found"
            />
          </Link>
        </span>
        <span>
          <Link to={routes.organization.list}>
            <img
              style={{width: '180px', height: 'auto'}}
              className="log__smnall"
              src={process.env.PUBLIC_URL + '/logo-dark.svg'}
              alt="logo not found"
            />
          </Link>
        </span>
      </div>
      <div className="sidebar__inner simple-bar">
        <div className="dlabnav">
          <ul className="metismenu" id="menu">
            <EventMenu />
          </ul>

          <div className="sidebar__copyright">
            <p>Всички права запазени @ Atomica Group 2024</p>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'

import Form from 'react-bootstrap/Form'
import TextEditor from 'utils/ui/TextEditor'

export function TextInput(props: {
  value: string
  setFieldValue: (field: string, value: string) => void
  label: string
  name: string
}) {
  const {value, setFieldValue, name} = props

  const handleOnChange = (value: string) => {
    setFieldValue(name, value)
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>
      <TextEditor value={value} onChange={handleOnChange} whiteMode={true} />
    </Form.Group>
  )
}

import React from 'react'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {DefaultBlockConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {VideoConfigType} from 'TemplateEditor/Layout/Blocks/Video/Video'
import {StringInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/StringInput'
import {DefaultBlockTopConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockTopConfig'
import {SwitchInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SwitchInput'

export function VideoConfig() {
  const {handleSubmit, values, setFieldValue} = useFormicForm<VideoConfigType>()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button className={'input__btn w-100 mb-20'} type="submit">
          Запазване
        </button>

        <DefaultBlockTopConfig values={values} setFieldValue={setFieldValue} />

        <SwitchInput
          value={values.controls}
          name={'controls'}
          label="Видео контроли"
          setFieldValue={setFieldValue}
        />

        <StringInput
          value={values.url}
          name={'url'}
          label="Адрес към видеото"
          setFieldValue={setFieldValue}
          info={
            <span
              style={{
                fontSize: 12,
                lineHeight: '18px',
                display: 'inline-block',
                paddingTop: 5,
              }}
            >
              YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia,
              Mixcloud, DailyMotion, Kaltura или адрес на файл
            </span>
          }
        />

        <DefaultBlockConfig values={values} setFieldValue={setFieldValue} />
      </form>
    </>
  )
}

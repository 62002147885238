import React, {useEffect} from 'react'
import {toast} from 'react-toastify'
import {useHistory, useLocation} from 'react-router-dom'
import {useOrganizationAuth} from 'organization/Auth/Providers/OrganizationAuthProvider'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import {useOrganizationRoutes} from 'organization/OrganizationRoutes'

export function GoogleCallback() {
  const {googleCallback} = useOrganizationAuth()
  const location = useLocation()
  const navigate = useHistory()
  const routes = useOrganizationRoutes()

  const acceptCallback = async () => {
    const result = await googleCallback(location.search)

    if (result === true) {
      toast.success('Успешен вход')
    }

    navigate.push(routes.root)
  }

  useEffect(() => {
    acceptCallback()
  }, [])

  return <FullPageLoader />
}

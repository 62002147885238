import React from 'react'

interface GetProps {
  title: string
  buttons?: React.JSX.Element
}

const BreadcrumMenu = ({title, buttons}: GetProps) => {
  return (
    <div className="breadcrumb__area">
      <div className="breadcrumb__wrapper mb-35">
        <div className="breadcrumb__main">
          <div className="breadcrumb__inner">
            <div className="breadcrumb__icon">
              <i className="flaticon-home"></i>
            </div>
            <div className="breadcrumb__menu">
              <nav>
                <ul>
                  <li>
                    <span>
                      {/* <Link to={routes.dashboard}>
                                    Home
                                </Link> */}
                    </span>
                  </li>
                  <li className="active">
                    <span> {title} </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {buttons}
        </div>
      </div>
    </div>
  )
}

export default BreadcrumMenu

import React, {useEffect, useState} from 'react'
import {LoginData} from 'organization/Auth/Interfaces/login'
import {RegisterData} from 'organization/Auth/Interfaces/register'

import {TeamMember} from 'organization/models/TeamMember'
import {
  organizationAccessTokenkey,
  organizationApiClient,
} from 'organization/organizationApiClient'
import {toast} from 'react-toastify'
import {UpdateProfileData} from 'organization/Auth/Interfaces/update_profile'
import {UpdatePasswordData} from 'organization/Auth/Interfaces/update_password'
import {
  PasswordRecoverData,
  PasswordRecoverUpdateData,
} from 'organization/Auth/Interfaces/password_recover'

export interface OrganizationAuthContextProps {
  user: TeamMember | null
  login: (data: LoginData) => Promise<string | boolean>
  register: (data: RegisterData) => Promise<string | boolean>
  updateProfile: (data: UpdateProfileData) => Promise<string | boolean>
  updatePassword: (data: UpdatePasswordData) => Promise<string | boolean>
  passwordRecover: (data: PasswordRecoverData) => Promise<string | boolean>
  passwordReset: (
    data: PasswordRecoverUpdateData,
    token: string,
    email: string,
  ) => Promise<string | boolean>
  logout: () => Promise<string | boolean>
  googleAuth: () => void
  googleCallback: (callback: string) => Promise<string | boolean>
}

export const OrganizationAuthContext = React.createContext<
  OrganizationAuthContextProps | undefined
>(undefined)

export default function OrganizationAuthProvider(props: {
  children: React.ReactElement
}) {
  const [user, setUser] = useState<TeamMember | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const apiClient = organizationApiClient()

  useEffect(() => {
    get()
  }, [])

  const saveToken = (token: any) => {
    localStorage.setItem(organizationAccessTokenkey, token.access_token)
  }

  const get = async () => {
    const result = await apiClient
      .get('/api/organization/user')
      .catch((error) => {
        return false
      })

    if (result) {
      setUser(result)
    }

    setLoading(false)
    return false
  }

  const register = async (data: RegisterData) => {
    const result = await apiClient
      .post('/api/organization/register', data)
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const login = async (data: LoginData) => {
    const result = await apiClient
      .post('/api/organization/login', data)
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const updateProfile = async (data: UpdateProfileData) => {
    const result = await apiClient
      .post('api/organization/update-profile', data)
      .then(setUser)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const updatePassword = async (data: UpdatePasswordData) => {
    const result = await apiClient
      .post('api/organization/update-password', data)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const passwordRecover = async (data: PasswordRecoverData) => {
    const result = await apiClient
      .post('api/organization/password-recover', data)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const passwordReset = async (
    data: PasswordRecoverUpdateData,
    token: string,
    email: string,
  ) => {
    const result = await apiClient
      .post('api/organization/password-reset', {
        ...data,
        token: token,
        email: email,
      })
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const logout = async () => {
    try {
      await apiClient.post('/api/organization/logout')
      setUser(null)
      localStorage.setItem(organizationAccessTokenkey, '')
      return true
    } catch (error: any) {
      toast.warning(error.message)
      return error.message
    }
  }

  const googleAuth = async () => {
    const result = await apiClient
      .get('api/organization/google/auth')
      .then((data) => {
        window.location.href = data.url

        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const googleCallback = async (callback: string) => {
    const result = await apiClient
      .get('api/organization/google/callback' + callback)
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  return (
    <OrganizationAuthContext.Provider
      value={{
        user,
        login,
        register,
        logout,
        updateProfile,
        updatePassword,
        passwordRecover,
        passwordReset,
        googleAuth,
        googleCallback,
      }}
    >
      {!loading && props.children}
    </OrganizationAuthContext.Provider>
  )
}

export function useOrganizationAuth() {
  const context = React.useContext(OrganizationAuthContext)
  if (context === undefined) {
    throw new Error(
      'useOrganizationAuth must be used within OrganizationAuthProvider',
    )
  }
  return context
}

import React from 'react'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {ButtonOneConfigType} from 'TemplateEditor/Layout/Blocks/ButtonOne/ButtonOne'
import {ColorInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ColorInput'
import {FontInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/FontInput'
import {LinkInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/LinkInput'
import {StringInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/StringInput'
import {DefaultBlockConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockTopConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockTopConfig'

export function ButtonOneConfig() {
  const {handleSubmit, values, setFieldValue} =
    useFormicForm<ButtonOneConfigType>()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button className={'input__btn w-100 mb-20'} type="submit">
          Запазване
        </button>

        <DefaultBlockTopConfig values={values} setFieldValue={setFieldValue} />

        <LinkInput
          value={values.link}
          type={values.linkType}
          name={'link'}
          typeName={'linkType'}
          label="Линк"
          setFieldValue={setFieldValue}
        />

        <FontInput
          value={values.font}
          name={'font'}
          label="Шрифт"
          setFieldValue={setFieldValue}
        />
        <StringInput
          value={values.text}
          name={'text'}
          label="Текст"
          setFieldValue={setFieldValue}
        />

        <ColorInput
          value={values.textColor}
          name={'textColor'}
          label="Цвят на текста"
          setFieldValue={setFieldValue}
        />

        <ColorInput
          value={values.backgroundColor}
          name={'backgroundColor'}
          label="Цвят на бутона"
          setFieldValue={setFieldValue}
        />

        <DefaultBlockConfig values={values} setFieldValue={setFieldValue} />
      </form>
    </>
  )
}

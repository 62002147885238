import React from 'react'

const UnderTitlearrow = () => {
  return (
    <>
      <svg
        className="title-underline"
        width="328"
        height="31"
        viewBox="0 0 328 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 29C110 8.62517 326 -19.8996 326 29"
          stroke="url(#paint0_linear_47_128)"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_47_1281"
            x1="2.50784"
            y1="22.0412"
            x2="322.486"
            y2="65.0473"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="1" stopColor="#F7426F" />
            <stop offset="1" stopColor="#F87A58" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default UnderTitlearrow

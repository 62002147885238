import {attendeeApiClient} from 'attendee/apiClient'
import {FullPageLoader} from 'attendee/theme/FullPageLoader'
import {EventModel} from 'event/models/Event'
import React, {useEffect, useState} from 'react'
import {useQueryParams} from 'utils/lib/url'

export interface AttendeeEventsListContextProps {
  events: EventModel[]
}

export const AttendeeEventsListContext = React.createContext<
  AttendeeEventsListContextProps | undefined
>(undefined)

export default function AttendeeEventsListProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = attendeeApiClient()
  const [events, setEvents] = useState<EventModel[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const {term} = useQueryParams()

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term])

  const get = async () => {
    const termQuery = term ? `?term=${term}` : ''
    const response = await apiClient.get(`api/events${termQuery}`)
    setEvents(response)
    setLoading(false)
  }

  return (
    <AttendeeEventsListContext.Provider
      value={{
        events,
      }}
    >
      {loading && <FullPageLoader />}
      {!loading && props.children}
    </AttendeeEventsListContext.Provider>
  )
}

export function useAttendeeEventsList() {
  const context = React.useContext(AttendeeEventsListContext)
  if (context === undefined) {
    throw new Error(
      'useAttendeeEventsList must be used within AttendeeEventsListProvider',
    )
  }
  return context
}

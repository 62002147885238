import React from 'react'
import {Link} from 'react-router-dom'
import {
  organizationEventAttendeeRoutes,
  useOrganizationEventRoutes,
} from 'organization/OrganizationRoutes'
import {Attendant} from 'organization/Event/Attendants/ViewAttendant/AttendantProvider'

export const ListAttendant = (props: {attendant: Attendant; index: number}) => {
  const {attendant, index} = props
  const organizationEventRoutes = useOrganizationEventRoutes()
  const routes = organizationEventAttendeeRoutes(
    attendant,
    organizationEventRoutes,
  )

  return (
    <tr key={index}>
      <td> {attendant.id} </td>
      <td>{attendant.attendee.first_name} </td>
      <td>{attendant.attendee.last_name}</td>
      <td>{attendant.attendee.email}</td>
      <td>
        <span className={'status__tag bg-green'}>
          <span>Активен</span>
        </span>
      </td>

      <td>
        <Link to={routes.view} className="input__btn">
          Преглед
        </Link>
      </td>
    </tr>
  )
}

import React from 'react'
import styled from 'styled-components'
import {BlockDrop} from 'TemplateEditor/Layout/Sections/BlockDrop'
import {DefaultSectionHolder} from 'TemplateEditor/Layout/Sections/DefaultSectionHolder'
import {
  defaultSectionConfig,
  SectionProps,
} from 'TemplateEditor/Layout/Sections/Section'
import {Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'

export const section1TypeId = 'section-1'

export function Section1(props: SectionProps) {
  const {section, sectionIndex, key} = props

  return (
    <DefaultSectionHolder section={section} key={key}>
      <div style={{width: '100%'}}>
        <BlockDrop section={section} index={1} sectionIndex={sectionIndex} />
      </div>
    </DefaultSectionHolder>
  )
}

export function PreviewSection1() {
  return (
    <PreviewSectionHolderDiv>
      <PreviewSectionDiv style={{width: '100%'}}>100%</PreviewSectionDiv>
    </PreviewSectionHolderDiv>
  )
}

export function newSection1(): Section {
  return {
    ...defaultSectionConfig,
    id: uuidv4(),
    type: section1TypeId,
    blocks: {},
  }
}

export const PreviewSectionDiv = styled.div`
  border: 1px #ffffff dashed;
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PreviewSectionHolderDiv = styled.div`
  display: flex;
  padding: 10px 0px;
  gap: 5px;
`

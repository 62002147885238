import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {Attendee} from 'attendee/models/attendee'
import {useComments} from 'event/Comments/CommentsProvider'
import moment from 'moment'
import React, {useState} from 'react'
import Avatar from 'react-avatar'

export function Comments() {
  const {user} = useAuth()

  const {comments, addComment, loading} = useComments()

  return (
    <>
      <АddComment user={user} addComment={addComment} disabled={loading} />

      {comments.map((comment, index) => {
        return (
          <div key={index} className="review__item mt-40">
            <div className="review__item-inner">
              <div className="review__item-thumb">
                <Avatar
                  name={
                    comment.attendee.first_name +
                    ' ' +
                    comment.attendee.last_name
                  }
                  round={true}
                  size="50"
                  textSizeRatio={3}
                />
              </div>
              <div className="review__item-content">
                <h4>
                  {comment.attendee.first_name} {comment.attendee.last_name}
                </h4>
                <ul>
                  <li>{moment(comment.created_at).fromNow()}</li>
                </ul>
              </div>
            </div>
            <p>{comment.comment}</p>
          </div>
        )
      })}
    </>
  )
}

function АddComment(props: {
  user: Attendee | null
  addComment: (comments: string) => Promise<string | boolean>
  disabled: boolean
}) {
  const [comment, setComment] = useState<string>('')
  const {user, addComment, disabled} = props

  const handleOnAdd = () => {
    setComment('')
    addComment(comment)
  }

  if (!user) {
    return (
      <div
        style={{
          background: '#f3f3f3',
          marginTop: 30,
          padding: 30,
          display: 'flex',
          gap: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Само регистрирани участници могат да коментират.
      </div>
    )
  }

  return (
    <div
      style={{
        background: '#f3f3f3',
        marginTop: 30,
        padding: 30,
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Avatar
        name={user.first_name + ' ' + user.last_name}
        round={true}
        size="50"
        textSizeRatio={3}
      />
      <textarea
        style={{
          border: '1px solid #f3f3f3',
          borderRadius: '4px',
          padding: 15,
          width: '100%',
          resize: 'none',
          outline: 'none',
        }}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value)
        }}
        name="short_description"
        disabled={disabled}
      ></textarea>
      <button  disabled={disabled}
         className={
          disabled
             ? 'disableBtnStyle '
             : 'input__btn '
         } onClick={handleOnAdd}>
        Добави
      </button>
    </div>
  )
}

import React, {ReactNode} from 'react'
import Form from 'react-bootstrap/Form'

export function TextAreaInput(props: {
  value: string
  setFieldValue: (field: string, value: string) => void
  label: string
  name: string
  info?: ReactNode
}) {
  const {value, setFieldValue, name} = props

  const handleOnChange = (value: string) => {
    setFieldValue(name, value)
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        onChange={(e) => {
          handleOnChange(e.target.value)
        }}
        as="textarea"
        rows={8}
        value={value}
      />
      {props.info}
    </Form.Group>
  )
}

import React, {useState} from 'react'

export interface ThemeProviderContextProps {
  SidebarHandle: () => void
  setIsCollapse: React.Dispatch<React.SetStateAction<boolean>>
  isCollapse: boolean
}

export const ThemeProviderContext = React.createContext<
  ThemeProviderContextProps | undefined
>(undefined)

export default function ThemeProviderProvider(props: {
  children: React.ReactElement
}) {
  const [isCollapse, setIsCollapse] = useState<boolean>(true)

  //sidebar open
  const SidebarHandle = () => {
    setIsCollapse(!isCollapse)
  }

  return (
    <ThemeProviderContext.Provider
      value={{
        setIsCollapse,
        SidebarHandle,
        isCollapse,
      }}
    >
      {props.children}
    </ThemeProviderContext.Provider>
  )
}

export function useTheme() {
  const context = React.useContext(ThemeProviderContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within ThemeProviderProvider')
  }
  return context
}

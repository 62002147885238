import React from 'react'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {DefaultBlockConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {ImageBlockConfigType} from 'TemplateEditor/Layout/Blocks/ImageBlock/ImageBlock'
import {ImageInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ImageInput'
import {Image} from 'organization/ImageSelector'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'
import {DefaultBlockTopConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockTopConfig'

export function ImageBlockConfig() {
  const {handleSubmit, values, setValues, setFieldValue} =
    useFormicForm<ImageBlockConfigType>()

  const handleOnSelect = (image: Image | null) => {
    setValues({
      ...values,
      image: image?.url,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button className={'input__btn w-100 mb-20'} type="submit">
          Запазване
        </button>

        <DefaultBlockTopConfig values={values} setFieldValue={setFieldValue} />

        <SliderInput
          value={values.width}
          name={'width'}
          label="Ширина"
          setFieldValue={setFieldValue}
          max={100}
          min={0}
          step={1}
        />

        <SliderInput
          value={values.mobileWidth}
          name={'mobileWidth'}
          label="Ширина при мобилна"
          setFieldValue={setFieldValue}
          max={100}
          min={0}
          step={1}
        />

        <ImageInput onSelect={handleOnSelect} label={'Изображение'} />

        <DefaultBlockConfig values={values} setFieldValue={setFieldValue} />
      </form>
    </>
  )
}

import Page from 'attendee/theme/Page'
import React from 'react'
import ProfileUpdate from 'attendee/Auth/Profile/ProfileUpdate'
import PasswordUpdate from 'attendee/Auth/Profile/PasswordUpdate'

export function Profile() {
  return (
    <Page>
      <section className="event__schedule-area dark_light_class pt-55 pb-150">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-12">
              <div className="setting__area">
                <div className="body__card-wrapper mb-20">
                  <div className="card__header-top">
                    <div className="card__title-inner">
                      <h4 className="event__information-title">
                        Основна информация
                      </h4>
                    </div>
                  </div>
                  <div className="setting__main-wrapper pt-25 pb-100">
                    <ProfileUpdate />
                  </div>

                  <div className="card__header-top">
                    <div className="card__title-inner">
                      <h4 className="event__information-title">
                        Промяна на парола
                      </h4>
                    </div>
                  </div>

                  <div className="setting__main-wrapper pt-25">
                    <PasswordUpdate />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

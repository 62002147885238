
import React from 'react'
import styled from 'styled-components'
import { ExampleTemplate} from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplatesProvider'
import { useTemplate } from 'TemplateEditor/TemplateProvider'

export default function ExampleTemplateItem(props: {
    exampleTemplate: ExampleTemplate
    toggleOpen: () => void
}) {
  const {exampleTemplate, toggleOpen} = props
  const {updateTemplate} = useTemplate()

  return (
    <ItemHolder>
        <ItemImageHolder><img src={process.env.PUBLIC_URL + `/example-templates/${exampleTemplate.image}`}/></ItemImageHolder>
        <ItemNameHolder>Име: {exampleTemplate.name}</ItemNameHolder>
        <ItemActionsHolder>
           <button
                  type="button"
                  className={'input__btn w-100 '}
                  onClick={()=> {
                    updateTemplate(exampleTemplate.template)
                    toggleOpen()
                    
                  }}
                >
                    Приложи
                </button>
        </ItemActionsHolder>
    </ItemHolder>
  )
}

const ItemHolder = styled.div`
        display: flex;
    justify-content: space-between;
    align-items: center;
`

const ItemNameHolder = styled.div`
    color: #333;
`

const ItemImageHolder = styled.div`
    & > img {
        width: 500px;
    }
`

const ItemActionsHolder = styled.div`

`
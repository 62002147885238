import React from 'react'

import Form from 'react-bootstrap/Form'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'

export function ColorInput(props: {
  value: string
  setFieldValue: (field: string, value: string) => void
  label: string
  name: string
}) {
  const {value, setFieldValue, name} = props

  const handleOnChange = (value: string) => {
    setFieldValue(name, value)
  }

  const handleOnClear = () => {
    setFieldValue(name, '')
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>

      <ColorInputsHolder>
        <ColorMainInput>
          <Form.Control
            style={{flex: 4}}
            onChange={(e) => {
              handleOnChange(e.target.value)
            }}
            value={value}
          />
          <div className="close">
            <CloseIcon onClick={handleOnClear} />
          </div>
        </ColorMainInput>

        <Form.Control
          style={{flex: 1, padding: 0}}
          onChange={(e) => {
            handleOnChange(e.target.value)
          }}
          value={value}
          type="color"
        />
      </ColorInputsHolder>
    </Form.Group>
  )
}

const ColorInputsHolder = styled.div`
  display: flex;
  gap: 15px;
`

const ColorMainInput = styled.div`
  position: relative;

  & > .close {
    position: absolute;
    right: 6px;
    top: 6px;
    color: black;
    cursor: pointer;
    font-width: bold;
  }
`

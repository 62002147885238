import {TemplateEditor} from 'TemplateEditor'
import EventPage from 'organization/Event/EventPage'
import React from 'react'

export const EventDesign = () => {
  return (
    <EventPage>
      <>
        <TemplateEditor />
      </>
    </EventPage>
  )
}

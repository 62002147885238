import React from 'react'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import {FieldValues, useForm} from 'react-hook-form'
import DashboardPageWithoutSideBar from 'organization/theme/DashboardPageWithoutSideBar'
import {useOrganizationBilling} from 'organization/Billing/Providers/OrganizationBillingProvider'
import useNewEventPayment from 'organization/Billing/Payments/useNewEventPayment'

export const CreateEvent = () => {
  const {register, handleSubmit} = useForm()

  const {payPayment} = useOrganizationBilling()
  const {payment} = useNewEventPayment()

  const handleEventCreate = (data: FieldValues) => {
    payPayment(payment(data))
  }

  return (
    <DashboardPageWithoutSideBar>
      <>
        <BreadcrumMenu title="Създаване на събитие" />

        <div className="row">
          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper">
                {/* create event form  */}
                <form onSubmit={handleSubmit(handleEventCreate)}>
                  <div className="create__event-main pt-25">
                    <div className="event__left-box">
                      <div className="create__input-wrapper">
                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Име на събитието <br />
                            <small>
                              <i>(може да бъде променето по-късно)</i>
                            </small>
                          </label>
                          <input type="text" {...register('name')} />
                        </div>

                        {/* <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Кратко описание
                          </label>
                          <input
                            type="text"
                            {...register('short_description')}
                          />
                        </div> */}

                        {/* <div className="event__input mb-15">
                          <label className="input__field-text">
                            Описание на събитието
                          </label>
                          <textarea
                            placeholder=""
                            {...register('description')}
                          ></textarea>
                        </div> */}
                        {/* <div className="row g-20">
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="singel__input-field is-color-change mb-15">
                              <label className="input__field-text">Дата</label>
                              <input type="date" {...register('date')} />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="singel__input-field is-color-change  mb-15">
                              <label className="input__field-text">Час</label>
                              <input type="time" {...register('time')} />
                            </div>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="singel__input-field">
                              <label className="input__field-text">
                                Цена на билет
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                defaultValue="10"
                                {...register('price')}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="singel__input-field mb-15">
                              <label className="input__field-text">
                                Общо билети
                              </label>
                              <input
                                type="number"
                                defaultValue="1000"
                                {...register('total_seats')}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="event__right-box">
                      <div className="pricing__item mb-30">
                        <div className="pricing__item-header">
                          <div>
                            <span className="pricing__item-title">
                              Цена: 179 лв.
                            </span>
                          </div>
                        </div>
                        <div className="pricing__item-content">
                          <ul>
                            <li>
                              <i className="flaticon-comment"></i> Собстена
                              страница
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Конструктор
                              на страницата
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Модул за
                              продажба на билети
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Автоматични
                              известия на участниците
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Хостинг на
                              събитието
                            </li>
                          </ul>
                        </div>
                        <div className="pricing__btn-wrap">
                          <button className="input__btn w-100" type="submit">
                            Създай събитие
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </DashboardPageWithoutSideBar>
  )
}

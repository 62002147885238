import React from 'react'
import {Balance} from 'organization/Event/Earning/EventEarningProvider'

export const ListBalance = (props: {balance: Balance; index: number}) => {
  const {balance, index} = props

  return (
    <tr key={index}>
      <td>
        <span className={'status__tag bg-green'}>
          <span>Приход</span>
        </span>
      </td>
      <td>Продажба на билети</td>
      <td>{balance.id}</td>
      <td>{balance.price} лв.</td>
    </tr>
  )
}

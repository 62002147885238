import React, {useEffect, useState} from 'react'
import {BlockElements} from 'TemplateEditor/Layout/Blocks'
import {SectionConfigType} from 'TemplateEditor/Layout/Sections/SectionConfig'
import {Template} from 'TemplateEditor/template'
import {useTemplate} from 'TemplateEditor/TemplateProvider'
import {updateNestedJsonWithStringKeys} from 'utils/lib/json'
import {confirmAlert} from 'react-confirm-alert'

export type Modes = 'section' | 'block'

export interface EditorContextProps {
  mode: Modes
  setMode: (mode: Modes) => void
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  mobileMode: boolean
  setMobileMode: (mobileMode: boolean) => void
  keepSidebar: boolean
  setKeepSidebar: (keepSidebar: boolean) => void
  setEditElement: (type: string | null) => void
  editElement: string | null
  updateElement: (block: BlockElements | SectionConfigType) => void
  setElement: (block: BlockElements | SectionConfigType) => void
}

export const EditorContext = React.createContext<
  EditorContextProps | undefined
>(undefined)

export default function EditorProvider(props: {
  children: React.ReactElement
  editMode?: boolean
}) {
  const {template, updateTemplate, setTemplate, syncedTemplate} = useTemplate()

  const [mode, setMode] = useState<Modes>('section')
  const [editMode, setEditMode] = useState<boolean>(props.editMode ?? true)
  const [mobileMode, setMobileMode] = useState<boolean>(false)
  const [keepSidebar, setKeepSidebar] = useState<boolean>(false)
  const [editElement, changeEditElement] = useState<string | null>(null)
  const [savedTemplate, setSavedTemplate] = useState<Template | null>(null)

  useEffect(() => {
    if (!savedTemplate) return
    setSavedTemplate(JSON.parse(JSON.stringify(template)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncedTemplate])

  const updateElement = (block: BlockElements | SectionConfigType) => {
    const updatedTemplate = updateNestedJsonWithStringKeys(
      template,
      editElement,
      block,
    )

    updateTemplate(updatedTemplate)
    setTemplate(updatedTemplate)
    changeEditElement(null)
  }

  const setElement = (block: BlockElements | SectionConfigType) => {
    const updatedTemplate = updateNestedJsonWithStringKeys(
      template,
      editElement,
      block,
    )
    setTemplate(updatedTemplate)
  }

  const setEditElement = (element: string | null) => {
    if (editElement !== element && savedTemplate) {
      // show modal

      const hasChanges =
        JSON.stringify(template) !== JSON.stringify(savedTemplate)

      if (hasChanges) {
        confirmAlert({
          message: 'Имате незапазени промени, искатели да ги запазите?',
          closeOnClickOutside: false,
          buttons: [
            {
              label: 'Да',
              onClick: () => {
                // save template
                updateTemplate(template)
                setTemplate(template)
                changeEditElement(null)
              },
            },
            {
              label: 'Не',
              onClick: () => {
                // revert template
                setTemplate(savedTemplate)
                setSavedTemplate(JSON.parse(JSON.stringify(savedTemplate)))
                changeEditElement(element)
              },
            },
          ],
        })
      } else {
        changeEditElement(element)
      }
    } else if (element === null && savedTemplate) {
      setTemplate(savedTemplate)
      changeEditElement(element)
    } else {
      setSavedTemplate(JSON.parse(JSON.stringify(template)))
      changeEditElement(element)
    }
  }

  return (
    <EditorContext.Provider
      value={{
        mode,
        setMode,
        editMode,
        setEditMode,
        mobileMode,
        setMobileMode,
        keepSidebar,
        setKeepSidebar,
        setEditElement,
        editElement,
        updateElement,
        setElement,
      }}
    >
      {props.children}
    </EditorContext.Provider>
  )
}

export function useEditor() {
  const context = React.useContext(EditorContext)
  if (context === undefined) {
    throw new Error('useEditor must be used within EditorProvider')
  }
  return context
}

import {PreviewTemplate} from 'TemplateEditor/PreviewTemplate'
import {WaitPage} from 'event/WaitPage'
import {useEventPreview} from 'event/providers/EventPreviewProvider'
import moment from 'moment'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {routes} from 'routes/routes'

export function Preview() {
  const {event} = useEventPreview()
  const navigate = useHistory()

  if (!event) return null

  if (!event.paid) {
    navigate.push(routes.root)
    return null
  }

  if (!event.page_date || moment(event.page_date).isAfter()) {
    return <WaitPage />
  }

  return <PreviewTemplate event={event} />
}

import {ListBlocks} from 'TemplateEditor/Layout/Blocks'
import React from 'react'
import {Draggable, Droppable} from 'react-beautiful-dnd'
import styled from 'styled-components'

export function BlocksList() {
  return (
    <Droppable isDropDisabled={true} droppableId="default-blocks" type="block">
      {(provided) => (
        <PreviewHolder {...provided.droppableProps} ref={provided.innerRef}>
          {ListBlocks &&
            ListBlocks.map((ListBlock, index) => (
              <Draggable
                draggableId={ListBlock.typeId}
                index={index}
                key={index}
              >
                {(provided) => {
                  return (
                    <ListItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      {ListBlock.preview()}
                    </ListItem>
                  )
                }}
              </Draggable>
            ))}
          {provided.placeholder}
        </PreviewHolder>
      )}
    </Droppable>
  )
}

export const PreviewHolderDiv = styled.div`
  text-align: center;

  & > svg {
    font-size: 30px;
  }

  & > span {
    margin-top: 5px;
    display: block;
  }
`

export const ListItem = styled.div`
  display: flex;
  padding: 10px 0px;
  flex-basis: calc(50% - 5px);
  align-items: center;
  justify-content: center;
  border: 1px dashed #ffffff;
  align-self: stretch;
`

export const PreviewHolder = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

import {useEventEarning} from 'organization/Event/Earning/EventEarningProvider'
import {ListBalance} from 'organization/Event/Earning/ListBalance'
import EventPage from 'organization/Event/EventPage'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React, {useState} from 'react'
import styled from 'styled-components'
import {Pagination} from '@mui/material'
import WithdrawModal from 'organization/Event/Earning/WithdrawModal'

export const Earning = () => {
  const {
    totalProfit,
    ticketSales,
    platformTax,
    balance,
    totalPages,
    page,
    changePage,
  } = useEventEarning()

  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false)

  const toggleWidthdraw = () => {
    setWithdrawOpen((curr) => !curr)
  }

  return (
    <EventPage
      modals={
        <WithdrawModal isOpen={withdrawOpen} toggleOpen={toggleWidthdraw} />
      }
    >
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Печалба" />

          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper attendant__wrapper">
                <MainInfoHolder>
                  <div>
                    <p style={{fontSize: '1.5rem'}}>
                      Обща печалба: <b>{totalProfit} лв.</b>
                    </p>

                    <p style={{fontSize: '1rem'}}>
                      Приходи от продажба на билети: <b>{ticketSales} лв.</b>
                    </p>
                    <p style={{fontSize: '1rem'}}>
                      Такси: <b>{platformTax} лв.</b>
                    </p>
                  </div>
                  <button
                    onClick={toggleWidthdraw}
                    className="input__btn w-auto"
                  >
                    Изтегляне на печалба
                  </button>
                </MainInfoHolder>

                <table>
                  <thead>
                    <tr>
                      <th>Тип</th>
                      <th>От</th>
                      <th>Номер</th>
                      <th>Сума</th>
                    </tr>
                  </thead>
                  <tbody>
                    {balance.length === 0 && (
                      <tr>
                        <td colSpan={6}>Няма регистрирани участници</td>
                      </tr>
                    )}
                    {balance &&
                      balance.map((item, index) => (
                        <ListBalance balance={item} index={index} />
                      ))}
                  </tbody>
                </table>

                <PaginationHolder>
                  <Pagination
                    count={totalPages}
                    shape="rounded"
                    page={page}
                    onChange={changePage}
                  />
                </PaginationHolder>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

const MainInfoHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--clr-border-1);
  padding-bottom: 40px;
  margin-bottom: 20px;
`

const PaginationHolder = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`

import EventPage from 'organization/Event/EventPage'
import EventAttendantsProvider from 'organization/Event/Attendants/EventAttendantsProvider'
import {Calendar} from 'organization/Event/Statistics/Calendar'
import TicketSolds from 'organization/Event/Statistics/TicketSolds'
import TotalSeats from 'organization/Event/Statistics/TotalSeats'
import React from 'react'

export const EventIndex = () => {
  return (
    <EventPage>
      <div className="row g-20 pl-30 pt-30 pb-30 pr-30">
        <EventAttendantsProvider>
          <TicketSolds />
        </EventAttendantsProvider>

        <TotalSeats />
        <Calendar />
      </div>
    </EventPage>
  )
}

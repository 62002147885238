'use client'
import React, {useState} from 'react'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import small_logo from 'assets/img/logo/logo-small.svg'
import {Show} from 'utils/ui/Show'
import {Link} from 'react-router-dom'
import {routes} from 'routes/routes'
import {useOrganizationAuth} from 'organization/Auth/Providers/OrganizationAuthProvider'
import {useOrganziationTheme} from 'organization/theme/OrganizationThemeProvider'
import HeaderNotification from 'organization/theme/Header/HeaderNotification'
import {ProfileLinks} from 'organization/theme/Header/ProfileLinks'

export const Header = (props: {fullWidth?: boolean}) => {
  const {user} = useOrganizationAuth()

  const {SidebarHandle} = useOrganziationTheme()
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false)

  const handleShowNotification = () => {
    setIsOpenNotification(!isOpenNotification)
  }

  const handleToggleFullScreen = () => {
    if (!isFullScreen) {
      enterFullScreen()
    } else {
      exitFullScreen()
    }
  }

  const enterFullScreen = () => {
    const docElement = document.documentElement

    if (docElement.requestFullscreen) {
      docElement.requestFullscreen()
    } else if ((docElement as any).mozRequestFullScreen) {
      ;(docElement as any).mozRequestFullScreen()
    } else if ((docElement as any).webkitRequestFullscreen) {
      ;(docElement as any).webkitRequestFullscreen()
    }
    setIsFullScreen(true)
  }

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if ((document as any).mozCancelFullScreen) {
      ;(document as any).mozCancelFullScreen()
    } else if ((document as any).webkitExitFullscreen) {
      ;(document as any).webkitExitFullscreen()
    }
    setIsFullScreen(false)
  }

  return (
    <div
      className="app__header__area"
      style={props?.fullWidth ? {width: '100%'} : {}}
    >
      <div className="app__header-inner">
        <div className="app__header-left">
          {props?.fullWidth && (
            <Link to={routes.organization.list} style={{height: '50px'}}>
              <img
                style={{width: '100%', height: '100%'}}
                src={small_logo}
                alt="logo not found"
              />
            </Link>
          )}

          {!props?.fullWidth && (
            <button onClick={SidebarHandle} id="sidebar__active">
              <div className="bar-icon-2">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          )}

          {/* <div className="app__herader-input p-relative">
            <input type="search" placeholder="Намери събитие . . ." />
            <button>
              {' '}
              <i className="flaticon-loupe"></i>
            </button>
          </div> */}
        </div>
        <div className="app__header-right">
          <div className="app__header-action">
            <ul>
              <li>
                <button onClick={handleToggleFullScreen}>
                  <div className="nav-item">
                    <div className="notification__icon">
                      <FullscreenIcon />
                    </div>
                  </div>
                </button>
              </li>
              {/* <Show if={user}>
                <li>
                  <HeaderNotification
                    handleShowNotification={handleShowNotification}
                    isOpenNotification={isOpenNotification}
                  />
                </li>
              </Show> */}
            </ul>
          </div>

          <Show if={user}>
            <ProfileLinks />
          </Show>
          <Show if={!user}>
            <div>
              <Link to={routes.login} className="fill__btn">
                Вход <i className="fa-regular fa-angle-right"></i>
              </Link>
            </div>
          </Show>
        </div>
      </div>
    </div>
  )
}

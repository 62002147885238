import React, {
  useState,
  useCallback,
  useRef,
  KeyboardEvent,
  MouseEvent,
} from 'react'
import {useClickAway} from 'react-use'

interface NiceSelectProps {
  options: {value: string; text: string}[]
  defaultCurrent: number
  setCategorys: React.Dispatch<React.SetStateAction<string>>
  placeholder?: string
  className?: string
  onChange: (item: {value: string; text: string}, name: string) => void
  name: string
}

const NiceSelect: React.FC<NiceSelectProps> = ({
  options,
  defaultCurrent,
  placeholder,
  setCategorys,
  className,
  onChange,
  name,
}) => {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(options[defaultCurrent])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, onClose)

  const currentHandler = (item: any) => {
    setCurrent(item)
    onChange(item, name)
    setCategorys(item.text)
    onClose()
  }

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setOpen((prev) => !prev)
    }
  }

  const stopPropagation = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()
  }

  return (
    <div
      className={`nice-select ${className || ''} ${open ? 'open' : ''}`}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      ref={ref}
    >
      <span className="current">{current?.text || placeholder}</span>
      <ul
        className="list"
        role="menubar"
        onClick={stopPropagation}
        onKeyPress={stopPropagation}
      >
        {options?.map((item, index) => (
          <li
            key={index}
            className={`option ${item.value === current?.value ? 'selected focus' : ''}`}
            role="menuitem"
            onClick={() => currentHandler(item)}
            onKeyPress={(e: KeyboardEvent<HTMLLIElement>) => {
              stopPropagation(e)
            }}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NiceSelect

import React, {useEffect, useState} from 'react'
import {attendeeApiClient} from 'attendee/apiClient'
import {useEvent} from 'event/providers/EventProvider'
import {toast} from 'react-toastify'
import {Attendee} from 'attendee/models/attendee'

type Comment = {
  comment: string
  attendee: Attendee
  created_at: Date
}

export interface CommentsContextProps {
  comments: Comment[]
  loading: boolean
  addComment: (comments: string) => Promise<string | boolean>
}

export const CommentsContext = React.createContext<
  CommentsContextProps | undefined
>(undefined)

export default function CommentsProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = attendeeApiClient()
  const [comments, setComments] = useState<Comment[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const {event} = useEvent()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    if (!event) return

    try {
      const response = await apiClient.get(`api/event/${event.id}/comments`)
      setComments(response)
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
    }
  }

  const addComment = async (comment: string) => {
    if (!event) return false
    setLoading(true)
    const result = await apiClient
      .post(`api/event/${event.id}/comments`, {comment})
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      }).finally(() => {
        setLoading(false)
      })

    return result
  }

  return (
    <CommentsContext.Provider
      value={{
        comments,
        loading,
        addComment,
      }}
    >
      {props.children}
    </CommentsContext.Provider>
  )
}

export function useComments() {
  const context = React.useContext(CommentsContext)
  if (context === undefined) {
    throw new Error('useComments must be used within CommentsProvider')
  }
  return context
}

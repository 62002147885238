import {useStripe} from '@stripe/react-stripe-js'
import {attendeeApiClient} from 'attendee/apiClient'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {routes} from 'routes/routes'

export default function usePayment() {
  const navigate = useHistory()
  const stripe = useStripe()
  const apiClient = attendeeApiClient()

  const confirmTicketPayment = async (
    clientSecret: string,
    intentId: string,
    confirmationUrl: string,
    onSuccess: () => void,
  ) => {
    if (!stripe) return
    const {error, setupIntent} = await stripe.handleNextAction({
      clientSecret: clientSecret,
    })

    if (error) {
      toast.success('Неуспешно плащане')
    } else {
      // notify back and confirm payment in back

      await apiClient
        .post(confirmationUrl, {
          intentId,
        })
        .then((result) => {
          toast.success('Плащането е успешно')
          onSuccess()
        })
        .catch((err) => {
          toast.success('Неуспешно плащане')
        })
    }
  }

  const newPayment = (
    url: string,
    confirmationUrl: string,
    onSuccess: () => void,
  ) => {
    return async (paymentMethodId: string) => {
      await apiClient
        .post(url, {
          paymentMethodId,
        })
        .then((data) => {
          onSuccess()
        })
        .catch(async (data) => {
          if (data.response.status === 401) {
            navigate.push(routes.login)
          }
          if (data.response.status === 471) {
            await confirmTicketPayment(
              data.response.data.client_secret,
              data.response.data.intent_id,
              confirmationUrl,
              onSuccess,
            )
          }
        })
    }
  }

  return {
    newPayment,
  }
}

import {Meeting} from 'event/models/Meeting'
import {UpdateMeetingData} from 'organization/Event/Management/UpdateMeetingData'
import {organizationApiClient} from 'organization/organizationApiClient'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useRouteOrganizationEventId} from 'utils/lib/url'
import {FullPageLoader} from 'organization/theme/FullPageLoader'

export interface MeetingContextProps {
  meeting: Meeting | null
  updateMeeting: (data: UpdateMeetingData) => Promise<string | boolean>
  getStartLink: () => Promise<string>
  loading: boolean
}

export const MeetingContext = React.createContext<
  MeetingContextProps | undefined
>(undefined)

export default function MeetingProvider(props: {children: React.ReactElement}) {
  const apiClient = organizationApiClient()
  const [meeting, setMeeting] = useState<Meeting | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const eventId = useRouteOrganizationEventId()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(`api/event/${eventId}/meeting`)
      setMeeting(response)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  const updateMeeting = async (data: UpdateMeetingData) => {
    const result = await apiClient
      .post(`api/event/${eventId}/meeting`, data)
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    if (result) return true

    return false
  }

  const getStartLink = async () => {
    const result = await apiClient
      .get(`api/event/${eventId}/meeting/start-link`)
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  return (
    <MeetingContext.Provider
      value={{
        meeting,
        updateMeeting,
        getStartLink,
        loading,
      }}
    >
      {props.children}
    </MeetingContext.Provider>
  )
}

export function useMeeting() {
  const context = React.useContext(MeetingContext)
  if (context === undefined) {
    throw new Error('useMeeting must be used within MeetingProvider')
  }
  return context
}

import React from 'react'
import {Layout} from 'TemplateEditor/Layout'
import EditorProvider from 'TemplateEditor/EditorProvider'
import TemplateProvider from 'TemplateEditor/TemplateProvider'
import {EventModel} from 'event/models/Event'
import FunctionsProvider from 'TemplateEditor/FunctionsProvider'
import {attendeeApiClient} from 'attendee/apiClient'
import {toast} from 'react-toastify'
import {useEventPreview} from 'event/providers/EventPreviewProvider'

export function PreviewTemplate(props: {event?: EventModel}) {
  const {event} = props

  return (
    <FunctionsProvider joinMeeting={useJoinMeeting()}>
      <TemplateProvider event={event}>
        <EditorProvider editMode={false}>
          <Layout />
        </EditorProvider>
      </TemplateProvider>
    </FunctionsProvider>
  )
}

function useJoinMeeting() {
  const apiClient = attendeeApiClient()
  const {event} = useEventPreview()

  const join = () => {
    apiClient
      .get(`api/event/${event?.id}/join-link`)
      .then((response: any) =>
        window.open(response, '_blank', 'noopener,noreferrer'),
      )
      .catch((error) => {
        toast.warning(error.response.data.message)
      })
      .finally(() => {})
  }

  return {
    join,
  }
}

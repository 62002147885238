import {PaymentMethod} from '@stripe/stripe-js'
import {useAttendeeBilling} from 'attendee/Billing/Providers/AttendeeBillingProvider'
import {Loader} from 'attendee/theme/Loader'
import { isEmptyArray } from 'formik'
import React, {useEffect} from 'react'
import styled from 'styled-components'

export function PaymentMethods(props: {
  onSelect?: (paymentMethodId: string) => void
  selectLabel?: string
}) {
  const {paymentMethods, getPaymentMethods, removePaymentMethod, loading} =
    useAttendeeBilling()

  useEffect(() => {
    if(isEmptyArray(paymentMethods) && loading){
      getPaymentMethods()
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loader />

  return (
    <div>
      {paymentMethods.map((paymentMethod) => {
        return (
          <div>
            <CreditCard
              card={paymentMethod.card}
              paymentMethodId={paymentMethod.id}
              removePaymentMethod={removePaymentMethod}
              loading={loading}
              {...props}
            />
          </div>
        )
      })}
    </div>
  )
}

function CreditCard(props: {
  card?: PaymentMethod.Card
  paymentMethodId: string
  removePaymentMethod: (paymentMethod: string) => Promise<void>
  loading: boolean
  onSelect?: (paymentMethodId: string) => void
  selectLabel?: string
}) {
  const {card, paymentMethodId, removePaymentMethod, loading} = props

  if (!card) return null

  return (
    <Card>
      <CardNumber>**** **** **** {card.last4}</CardNumber>
      <CardName> {card.brand}</CardName>
      <CardExp>
        {card.exp_month}/{card.exp_year}
      </CardExp>
      <BottomButton
        disabled={loading}
        onClick={() => {
          props.onSelect
            ? props.onSelect(paymentMethodId)
            : removePaymentMethod(paymentMethodId)
        }}
      >
        {props.selectLabel ?? 'Премахване'}
      </BottomButton>
    </Card>
  )
}

const Card = styled.div`
  margin: 20px 0px;
  position: relative;
  height: 130px;
  width: 100%;
  border-radius: 25px;
  background: #ebebeb;
  border: 2px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;

  & > div,
  & > button {
    position: absolute;
    font-weight: 400;
    letter-spacing: 2px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
  }
`
const CardNumber = styled.div`
  left: 40px;
  top: 40px;
  font-size: 1.2rem;
`
const CardName = styled.div`
  font-size: 1.5rem;
  left: 40px;
  top: 10px;
`
const CardExp = styled.div`
  font-size: 1rem;
  top: 10px;
  right: 40px;
`
const BottomButton = styled.button`
  font-size: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: linear-gradient(91deg, #f7426f 5.15%, #f87a58 99.88%);
  color: #fff;
  padding: 10px 0px;
`

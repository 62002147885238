import PayModal from 'organization/Billing/PayModal'
import ImagesModal from 'organization/ImageSelector/ImagesModal'
import React from 'react'

export default function Modals(props: {modals?: React.ReactElement}) {
  return (
    <>
      <PayModal />
      <ImagesModal />
      {props.modals}
    </>
  )
}

import {LoginData} from 'attendee/Auth/Interfaces/login'
import {
  PasswordRecoverData,
  PasswordRecoverUpdateData,
} from 'attendee/Auth/Interfaces/password_recover'
import {RegisterData} from 'attendee/Auth/Interfaces/register'
import {UpdatePasswordData} from 'attendee/Auth/Interfaces/update_password'
import {UpdateProfileData} from 'attendee/Auth/Interfaces/update_profile'
import {attendeeAccessTokenkey, attendeeApiClient} from 'attendee/apiClient'
import {Attendee} from 'attendee/models/attendee'

import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'

export interface AuthContextProps {
  user: Attendee | null
  login: (data: LoginData) => Promise<string | boolean>
  register: (data: RegisterData) => Promise<string | boolean>
  updateProfile: (data: UpdateProfileData) => Promise<string | boolean>
  updatePassword: (data: UpdatePasswordData) => Promise<string | boolean>
  passwordRecover: (data: PasswordRecoverData) => Promise<string | boolean>
  passwordReset: (
    data: PasswordRecoverUpdateData,
    token: string,
    email: string,
  ) => Promise<string | boolean>
  logout: () => Promise<string | boolean>
  googleAuth: () => void
  googleCallback: (callback: string) => Promise<string | boolean>
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined,
)

export default function AuthProvider(props: {children: React.ReactElement}) {
  const apiClient = attendeeApiClient()
  const [user, setUser] = useState<Attendee | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    get()
  }, [])

  const saveToken = (token: any) => {
    localStorage.setItem(attendeeAccessTokenkey, token.access_token)
  }

  const get = async () => {
    const result = await apiClient.get('api/user').catch((error) => {
      return false
    })

    if (result) {
      setUser(result)
    }

    setLoading(false)
    return false
  }

  const register = async (data: RegisterData) => {
    const result = await apiClient
      .post('api/register', data)
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const login = async (data: LoginData) => {
    const result = await apiClient
      .post('api/login', data)
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const updateProfile = async (data: UpdateProfileData) => {
    const result = await apiClient
      .post('api/update-profile', data)
      .then(setUser)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const updatePassword = async (data: UpdatePasswordData) => {
    const result = await apiClient
      .post('api/update-password', data)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const passwordRecover = async (data: PasswordRecoverData) => {
    const result = await apiClient
      .post('api/password-recover', data)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const logout = async () => {
    try {
      await apiClient.post('api/logout')
      setUser(null)
      return true
    } catch (error: any) {
      toast.warning(error.message)
      return error.message
    }
  }

  const passwordReset = async (
    data: PasswordRecoverUpdateData,
    token: string,
    email: string,
  ) => {
    const result = await apiClient
      .post('api/password-reset', {
        ...data,
        token: token,
        email: email,
      })
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const googleAuth = async () => {
    const result = await apiClient
      .get('api/google/auth')
      .then((data) => {
        window.location.href = data.url

        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const googleCallback = async (callback: string) => {
    const result = await apiClient
      .get('api/google/callback' + callback)
      .then(saveToken)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        register,
        logout,
        updateProfile,
        updatePassword,
        passwordRecover,
        passwordReset,
        googleAuth,
        googleCallback,
      }}
    >
      {!loading && props.children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return context
}

import {ErrorMsg} from 'attendee/Auth/Register'
import NiceSelect from 'attendee/Contacts/NiceSelect'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'

export function ContactForm() {
  const [category, setCategorys] = useState(' ')
  const {
    handleBlur,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      category: category,
      message: '',
    },
    validationSchema: {},
    onSubmit: async (values) => {
      try {
        resetForm()
        toast.success('Contact Successfully')
      } catch (error) {}
    },
  })
  const selectHandler = (e: any) => {}

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className=" col-12 col-md-6 contact_div">
            <div className="form-group m-0">
              <input
                className="form-control"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Име"
                required
              />
            </div>
            {touched.name && <ErrorMsg errors={errors.name} />}
          </div>
          <div className=" col-12 col-md-6 contact_div">
            <div className="form-group m-0">
              <input
                className="form-control"
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Фамилия"
              />
            </div>
          </div>
          <div className=" col-12 col-md-6 contact_div">
            <div className="form-group m-0">
              <input
                className="form-control"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Имейл адрес"
                required
              />
            </div>
            {touched.email && <ErrorMsg errors={errors.email} />}
          </div>
          <div className=" col-12 col-md-6 contact_div">
            <div className="select_option m-0">
              <NiceSelect
                options={[
                  {value: 'Избери тема', text: 'Избери тема'},
                  {value: 'Website Development', text: 'Website Development'},
                  {value: 'UX/UI Design', text: 'UX/UI Design'},
                  {value: 'App Development', text: 'App Development'},
                  {value: 'Video Editing', text: 'Video Editing'},
                  {value: 'Programming & Tech', text: 'Programming & Tech'},
                  {value: 'Business Consuting', text: 'Business Consuting'},
                ]}
                defaultCurrent={0}
                onChange={selectHandler}
                name="Избери тема"
                setCategorys={setCategorys}
              />
            </div>
          </div>
          <div className="col contact_div">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Съобщение"
                required
              ></textarea>
            </div>
            {touched.message && <ErrorMsg errors={errors.message} />}
            <div className="header__btn">
              <button className="blog_custome_btn" type="submit">
                Изпрати съобщение <i className="fa-regular fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import {BlockDrop} from 'TemplateEditor/Layout/Sections/BlockDrop'
import {DefaultSectionHolder} from 'TemplateEditor/Layout/Sections/DefaultSectionHolder'
import {
  defaultSectionConfig,
  SectionProps,
} from 'TemplateEditor/Layout/Sections/Section'
import {
  PreviewSectionDiv,
  PreviewSectionHolderDiv,
} from 'TemplateEditor/Layout/Sections/Types/Section1'
import {Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'

export const section1by2TypeId = 'section-1-2'

export function Section1by2(props: SectionProps) {
  const {section, sectionIndex, key} = props

  return (
    <DefaultSectionHolder section={section} key={key}>
      <>
        <ResponsiveSections1 >
          <BlockDrop section={section} index={1} sectionIndex={sectionIndex} />
        </ResponsiveSections1>
        <ResponsiveSections2 >
          <BlockDrop section={section} index={2} sectionIndex={sectionIndex} />
        </ResponsiveSections2>
      </>
    </DefaultSectionHolder>
  )
}

export function PreviewSection1by2() {
  return (
    <PreviewSectionHolderDiv>
      <PreviewSectionDiv style={{width: '33.3%'}}>33.3%</PreviewSectionDiv>
      <PreviewSectionDiv style={{width: '66.6%'}}>66.6%</PreviewSectionDiv>
    </PreviewSectionHolderDiv>
  )
}

export function newSection1by2(): Section {
  return {
    ...defaultSectionConfig,
    id: uuidv4(),
    type: section1by2TypeId,
    blocks: {},
  }
}


const ResponsiveSections1 = styled.div`
  width: 33.3%; 

   @container (max-width: 991px) {
    width: 100%;
  } 
`

const ResponsiveSections2 = styled.div`
  width: 66.6%; 

   @container (max-width: 991px) {
    width: 100%;
  } 
`
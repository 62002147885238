import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'

import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing'
import {useEditor} from 'TemplateEditor/EditorProvider'

export const SpacerTypeId = 'block-spacer'

export function Spacer(props: {
  block: SpacerConfigType
  section: Section
  setEditMode: () => void
  key: string | number
}) {
  const {block, setEditMode} = props

  const {editMode} = useEditor()

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <div
        style={{
          height: block.height + 'px',
          width: '100%',
          background: editMode
            ? 'repeating-linear-gradient(135deg, #ffe4e4 0%, #ffe4e4 1%, transparent 1%, transparent 2%)'
            : '',
        }}
      ></div>
    </DefaultBlockHolder>
  )
}

export function PreviewSpacer() {
  return (
    <PreviewHolderDiv>
      <FormatLineSpacingIcon />
      <span>Разделител</span>
    </PreviewHolderDiv>
  )
}

export type SpacerConfigType = Block &
  DefaultBlockConfigType & {
    height: number
  }

export function newSpacer(): SpacerConfigType {
  return {
    ...DefaultBlockConfigValues,
    id: uuidv4(),
    type: SpacerTypeId,
    height: 20,
  }
}

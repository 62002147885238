import {Organization} from 'organization/models/Organization'
import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'

export interface OrganizationsContextProps {
  organizations: Organization[] | null
}

export const OrganizationsContext = React.createContext<
  OrganizationsContextProps | undefined
>(undefined)

export default function OrganizationsProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [loading, setLoading] = useState<boolean>(true)
  const [organizations, setOrganizations] = useState<Organization[] | null>(
    null,
  )

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get('api/organizations')
      setOrganizations(response)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
      }}
    >
      {loading && <FullPageLoader />}
      {!loading && props.children}
    </OrganizationsContext.Provider>
  )
}

export function useOrganizations(loader?: React.ReactElement) {
  const context = React.useContext(OrganizationsContext)
  if (context === undefined) {
    throw new Error(
      'useOrganizations must be used within OrganizationsProvider',
    )
  }
  return context
}

import Page from 'attendee/theme/Page'
import React from 'react'
import {EventListItem} from 'attendee/EventListItem'
import {useAttendeeEventsList} from 'attendee/providers/AttendeeEventsListProvider'
import styled from 'styled-components'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import EmailIcon from '@mui/icons-material/Email'
import {Link} from 'react-router-dom'
import CounterShape from 'assets/img/shape/counter-shape.png'
import {routes} from 'routes/routes'
import Shape1 from 'assets/img/shape/join-shape-1.png'
import Shape2 from 'assets/img/shape/join-shape-2.png'
import hometalker from 'assets/custom_images/hometalker.png'
import home1 from 'assets/custom_images/home1.png'
import home2 from 'assets/custom_images/home2.png'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'

export function Index() {
  const {events} = useAttendeeEventsList()
  const {user} = useAuth()

  return (
    <Page>
      <>
        <section className="event__schedule-area dark_light_class pt-55 pb-35 ">
          <div className="container">
            <div className="section__title-wrapper is-left mb-50">
              <span className="section__back-title">ПС</span>
              <span className="section__subtitle">График на събитията</span>
              <h2 className="section__title">
                Предстоящи <span className="text__highlight">Събития</span>
              </h2>
            </div>

            <div className="row">
              {events.length > 0 &&
                events.map((event, index) => (
                  <EventListItem event={event} index={index} />
                ))}
            </div>
          </div>
        </section>

        <section className="event__schedule-area dark_light_class pt-55 pb-35 ">
          <div className="container">
            <div className="section__title-wrapper is-left mb-50">
              <span className="section__back-title">ИР</span>
              <span className="section__subtitle">График на събитията</span>
              <h2 className="section__title">
                Избор на <span className="text__highlight">Редактора</span>
              </h2>
            </div>

            <div className="row">
              {events.length > 0 &&
                events.map((event, index) => (
                  <EventListItem event={event} index={index} />
                ))}
            </div>
          </div>
        </section>

        <section className="counter__area include__bg pt-150 pb-120 counter__overlay fix">
          <div className="counter__shape">
            <img
              src={CounterShape}
              style={{width: 'auto', height: 'auto'}}
              alt="image not found"
            />
          </div>
          <div className="container">
            <div className="row" style={{alignItems: 'center'}}>
              <HelpText>Нужна ти е помощ?</HelpText>

              <HelpButtonHolder>
                <HelpButton className="input__btn" to={routes.info}>
                  <QuestionMarkIcon /> <span>Информация</span>
                </HelpButton>
                <HelpButton className="input__btn" to={routes.contacts}>
                  <EmailIcon /> <span>Контакти</span>
                </HelpButton>
              </HelpButtonHolder>
            </div>
          </div>
        </section>

        <section
          id="homeabout"
          className={`about__area p-relative pt-150 dark_light_class `}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="about__content-wrapper mb-60">
                  <div className="section__title-wrapper mb-80">
                    <span className="section__back-title">ОС</span>
                    <span className="section__subtitle">Онлайн събития</span>
                    <h2 className="section__title">
                      Изживей едно истинско цифрово{' '}
                      <span className="text__highlight">преживяване</span>
                    </h2>
                  </div>
                  <div className="about__content">
                    <div className="about__text">
                      <h4>
                        Впусни се в динамичния и вълнуващ свят на съвременните
                        технологии.
                      </h4>
                      <p className="mb-30">
                        {' '}
                        Интерактивната платформа предлага персонализирано и
                        ангажиращо изживяване, което може да трансформира
                        начина, по който учиш, работиш и се забавляваш.
                      </p>
                      <p>
                        Регистрирай се, за да бъдеш сред първите, които ще
                        получат достъп до новите събития!
                      </p>
                    </div>
                    <div className="about__location-info mb-80">
                      <div className="about__location-inner">
                        <div className="about__location-icon">
                          <Link to="#">
                            <i className="fa-solid fa-location-dot"></i>
                          </Link>
                        </div>
                        <div className="about__location-address">
                          <h5>Къде и кога</h5>
                          <span>
                            На всякъде  <br /> и по всяко време
                          </span>
                        </div>
                      </div>
                      <div className="pluse__status">
                        <span className="dot"></span>
                        <span className="text">Онлайн</span>
                      </div>
                    </div>
                  </div>
                  {user && (
                    <Link to={routes.info} className="fill__btn">
                      Научи повече<i className="fa-regular fa-angle-right"></i>
                    </Link>
                  )}
                  {!user && (
                    <Link to={routes.register} className="fill__btn">
                      Регистирай се сега
                      <i className="fa-regular fa-angle-right"></i>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="about__thumb-wrapper mb-60 p-relative">
                  <div className="about__thumb-1 text-end w-img">
                    <img
                      src={home2}
                      style={{width: '100%', height: 'auto'}}
                      alt="image not found"
                    />
                    <div className="panel wow"></div>
                  </div>
                  <div className="about__thumb-2 w-img">
                    <img
                      src={home1}
                      style={{width: '100%', height: 'auto'}}
                      alt="image not found"
                    />
                    <div className="panel wow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="join__area p-relative  z-index-1 fix"
          style={{backgroundImage: `url(${hometalker})`}}
        >
          <div className="join__all-shape p-relative">
            <img
              className="join__shape-1"
              src={Shape1}
              style={{width: 'auto', height: 'auto'}}
              alt="image not found"
            />
            <img
              className="join__shape-2"
              src={Shape2}
              style={{width: 'auto', height: 'auto'}}
              alt="image not found"
            />
            <div className="container">
              <div className="join__main-wrapper pt-150 pb-90">
                <div className="row align-items-sm-start">
                  <div className="col-xxl-6 col-xl-6 col-lg-6">
                    <div className="join__features-wrapper mb-60">
                      <div className="join__features-grid">
                        <div className="join__features-item text-center">
                          <div className="join__features-icon">
                            <span>
                              <i className="flaticon-network"></i>
                            </span>
                          </div>
                          <h3 className="join__features-title">
                            {' '}
                            Изградена мрежа
                          </h3>
                        </div>
                        <div className="join__features-item text-center">
                          <div className="join__features-icon">
                            <span>
                              {' '}
                              <i className="flaticon-dashboard-2"></i>
                            </span>
                          </div>
                          <h3 className="join__features-title">Конструктор</h3>
                        </div>
                        <div className="join__features-item text-center">
                          <div className="join__features-icon">
                            <span>
                              <i className="flaticon-trophy-1"></i>
                            </span>
                          </div>
                          <h3 className="join__features-title">
                            Лесна продажба
                          </h3>
                        </div>
                        <div className="join__features-item text-center">
                          <div className="join__features-icon">
                            <span>
                              {' '}
                              <i className="flaticon-confetti"></i>
                            </span>
                          </div>
                          <h3 className="join__features-title">Много забава</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6">
                    <div className="join__content-wrapper mb-60">
                      <div className="section__title-wrapper is-white mb-40">
                        <span className="section__back-title">ОС</span>
                        <span className="section__subtitle">Предимства</span>
                        <h2 className="section__title">
                          Защо{' '}
                          <span className="text__highlight">
                            {' '}
                            Онлайн събитие
                          </span>{' '}
                          ?
                        </h2>
                      </div>
                      <div className="join__content mb-70">
                        <p>
                          Създаването на онлайн събитие има множество
                          предимства, които го правят изключително привлекателно
                          в съвременния дигитален свят.
                          <br /> <br />
                          Ето няколко ключови причини защо трябва да обмислите
                          организирането на такова събитие при нас:
                        </p>
                        <StyledUL className="mt-30">
                          <li>
                            <p>
                              <span>1</span> Лесна продажба на билети
                            </p>
                            <p>
                              <span>2</span> Намалени разходи
                            </p>
                            <p>
                              <span>3</span> Гъвкавост и иновации
                            </p>
                            <p>
                              <span>4</span> Екологичност
                            </p>
                            <p>
                              <span>5</span> Лесно измерване на успеха
                            </p>
                            <p>
                              <span>6</span> Глобален обхват
                            </p>
                          </li>
                        </StyledUL>
                      </div>
                      <Link
                        to={routes.organization.register}
                        className="fill__btn fill_btn_new"
                      >
                        Регистрация на организация сега
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="counter__area include__bg pt-150 pb-120 counter__overlay fix">
          <div className="counter__shape">
            <img
              src={CounterShape}
              style={{width: 'auto', height: 'auto'}}
              alt="image not found"
            />
          </div>
          <div className="container">
            <div className="row" style={{alignItems: 'center'}}>
              <HelpText>Искаш собствено събитие?</HelpText>

              <HelpButtonHolder>
                <HelpButton
                  className="input__btn"
                  to={routes.organization.register}
                >
                  <CorporateFareIcon /> <span>Регистрация на организация</span>
                </HelpButton>
              </HelpButtonHolder>
            </div>
          </div>
        </section>

        {/* https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaYoAISvev2iYrdwnapG0NTu7jnc6JcoTemN4VefNaoE80cLwFQyQF1srquyLz1kLylMs&usqp=CAU */}

        {/* <div className="partner__area grey-bg pt-100 pb-100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="section__title-wrapper is-left mb-110">
                                <span className="section__back-title">НП</span>
                                <span className="section__subtitle">Със съдействието на</span>
                                <h2 className="section__title">
                                    Нашите <span className="text__highlight"> партньори
                                    </span>
                                </h2>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="partner__grid-wrapper">
                                {brand_data.length > 0 &&
                                    brand_data.map((item, index) => (
                                        <div key={index} className="partner__item">
                                            <div className="partner__thumb">
                                                <Link to="#">
                                                    <img src={item.thubmnail} style={{ width: "auto", height: "auto" }} alt="image not found" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </>
    </Page>
  )
}

const HelpText = styled.div`
  color: white;
  font-size: 3rem;
  line-height: 3rem;

  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
  }
`

const HelpButtonHolder = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 35px;
  }
`

const HelpButton = styled(Link)`
  width: 100%;
  background: #ffffff;
  color: #f7757a;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 5px;

  & > svg {
    margin-right: auto;
  }

  & > span {
    margin-right: auto;
  }

  &:hover {
    background: var(--clr-gradient-14);
    color: #ffffff;
  }

   @media (max-width: 440px) {
    & > span{
      font-size: 1.1rem;
    }
  }
`

const StyledUL = styled.ul`
  & > li {
    display: flex;
    flex-flow: wrap;
    gap: 20px 0;
  }

  & > li > p {
    width: 50%;
  }
  & > li > p > span {
    background: var(--clr-theme-1);
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-weight: bold;
    font-size: 1.3rem;
  }

  @media (max-width: 575px) {
     & > li > p {
    width: 100%;
  }
  }
`

import React from 'react'

export interface FunctionsContextProps {
  joinMeeting: {join: () => void} | undefined
}

export const FunctionsContext = React.createContext<
  FunctionsContextProps | undefined
>(undefined)

export default function FunctionsProvider(props: {
  children: React.ReactElement
  joinMeeting?: {join: () => void}
}) {
  return (
    <FunctionsContext.Provider
      value={{
        joinMeeting: props.joinMeeting,
      }}
    >
      {props.children}
    </FunctionsContext.Provider>
  )
}

export function useFunctions() {
  const context = React.useContext(FunctionsContext)
  if (context === undefined) {
    throw new Error('useFunctions must be used within FunctionsProvider')
  }
  return context
}

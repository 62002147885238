import React, {ReactNode} from 'react'
import Form from 'react-bootstrap/Form'
import {DefaultBlockConfigType} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'

export function VisibilityInput(props: {
  value: DefaultBlockConfigType['visibility']
  setFieldValue: (field: string, value: string) => void
  label: string
  name: string
  info?: ReactNode
}) {
  const {value, setFieldValue, name} = props

  const handleOnChange = (value: DefaultBlockConfigType['visibility']) => {
    setFieldValue(name, value)
  }

  const left = () => {
    if (value === 'desktop') {
      return '33%'
    }

    if (value === 'mobile') {
      return '66%'
    }

    return '4px'
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>

      <div className={`bd-theme-toggle`}>
        <label className="bd-theme-toggle-main" style={{width: '100%'}}>
          <span
            className={`bd-theme-toggle-light-triple`}
            onClick={() => {
              handleOnChange('all')
            }}
          >
            Всички
          </span>
          <span
            className={`bd-theme-toggle-light-triple `}
            onClick={() => {
              handleOnChange('desktop')
            }}
          >
            Компютър
          </span>
          <span
            className={`bd-theme-toggle-light-triple `}
            onClick={() => {
              handleOnChange('mobile')
            }}
          >
            Телефон
          </span>
          <i
            className="bd-theme-toggle-slide-triple"
            style={{left: left(), right: 'auto'}}
          ></i>
        </label>
      </div>

      {props.info}
    </Form.Group>
  )
}

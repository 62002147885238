import React from 'react'
import {EventModel, getEventImageUrl} from 'event/models/Event'
import {Link} from 'react-router-dom'
import {eventRoutes} from 'event/EventRoutes'
import moment from 'moment'
import 'moment/locale/bg'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import EventSeatIcon from '@mui/icons-material/EventSeat'
import styled from 'styled-components'
import {publicImageUrl} from 'organization/ImageSelector'
import defaultEventImage from 'images/defaultEventImage.webp'

export const EventListItem = (props: {event: EventModel; index: number}) => {
  const {event, index} = props
  const routes = eventRoutes(event)

  const eventImage = getEventImageUrl(event, 1)

  const background = eventImage ? publicImageUrl(eventImage) : defaultEventImage

  return (
    <div
      key={index}
      className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-xs-12"
    >
      <div
        className="event__venue-card mb-20"
        style={{
          borderTop: '2px solid #f7426f',
          borderBottom: '2px solid #f7426f',
          background: '#f5f5f5',
        }}
      >
        <div className="event__venue-inner">
          <div className="event__venue-content">
            <div className="event__venue-meta">
              <DateHolder className="news__meta-status">
                <div>
                  <span className="mr-5">
                    <i className="flaticon-calendar-1"></i>
                  </span>
                  <span>
                    {event.date
                      ? moment(event.date).format('DD MMMM YYYY')
                      : 'Няма информация'}
                  </span>
                </div>
                <div>
                  <span className="mr-5">
                    <i className="flaticon-clock"></i>
                  </span>
                  <span>
                    {event.date
                      ? moment(event.date).format('HH:mm')
                      : 'Няма информация'}{' '}
                  </span>
                </div>
              </DateHolder>
              <div className="news__meta-status">
                <PriceHolder>{event.price} лв.</PriceHolder>
              </div>
            </div>
            <NameHolder className="event__venue-title">
              <Link to={routes.view}>{event.name}</Link>
            </NameHolder>
            {/* <p>{event.short_description}</p> */}
          </div>
          <div className="event__venue-thumb ">
            <ImageHolder background={background} />

            <div className="event__tag-post">
              <div className="event__tag-item">
                <span>
                  <TrendingUpIcon /> Набира популярност
                </span>
              </div>
              <div className="event__tag-item">
                <span>
                  <EventSeatIcon />
                  {event.total_seats - event.occupied_seats} свободни места
                </span>
              </div>
            </div>
          </div>
          <Link to={routes.view} className={'input__btn w-100 mt-30'}>
            Преглед на събитието
          </Link>
        </div>
      </div>
    </div>
  )
}

const ImageHolder = styled.div<{background: string | undefined}>`
  background-image: url(${(props) => props.background});
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: contain;
`

const DateHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`

const PriceHolder = styled.span`
  background: linear-gradient(91deg, #f7426f 5.15%, #f87a58 99.88%);
  background-clip: text;
  font-size: 2rem !important;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
`

const NameHolder = styled.h4`
  font-weight: bold;
`

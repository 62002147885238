import React from 'react'
import styled from 'styled-components'
import {Section} from 'TemplateEditor/template'

export function DefaultSectionHolder(props: {
  children: JSX.Element
  section: Section
  key: number | string
}) {
  return <Holder section={props.section}>{props.children}</Holder>
}

const Holder = styled.div<{section: Section}>`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: ${(props) => props.section.verticalPadding}px
    ${(props) => props.section.horizontalPadding}px;
  background: ${(props) => props.section.background ?? '#fffff'};
  border-color: ${(props) => props.section.borderColor ?? '#ffffff'};
  border-width: ${(props) => props.section.borderWidth}px;
  border-radius: ${(props) => props.section.borderRadius}%;
  border-style: solid;

   @container (max-width: 991px) {
    flex-direction: ${(props) => props.section.revertMobile ? 'column-reverse' : 'column'};
  } 
`

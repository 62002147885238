import {EventModel} from 'event/models/Event'
import {useParams} from 'react-router-dom'
import {routes, routesWithValue} from 'routes/routes'
import {Route, Switch} from 'react-router-dom'
import React from 'react'
import {View} from 'event/View'
import {Preview} from 'event/Preview'
import EventPreviewProvider from 'event/providers/EventPreviewProvider'
import EventProvider from 'event/providers/EventProvider'

export function useParamEventId() {
  const {eventId} = useParams<{eventId: string}>()
  return eventId
}

export function useEventRoutes() {
  const id = useParamEventId()
  return routesWithValue(':eventId', String(id), routes.event[':eventId'])
}

export function eventRoutes(event: Pick<EventModel, 'id'>) {
  return routesWithValue(':eventId', String(event.id), routes.event[':eventId'])
}

export function EventRoutes() {
  return (
    <>
      <Switch>
        <Route path={routes.event[':eventId'].view}>
          <EventProvider>
            <View />
          </EventProvider>
        </Route>
        <Route path={routes.event[':eventId'].preview}>
          <EventPreviewProvider>
            <Preview />
          </EventPreviewProvider>
        </Route>
      </Switch>
    </>
  )
}

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import React, {ReactElement} from 'react'

const stripePublicKey = process.env.REACT_APP_STRIPE_PK as string
const stripePromise = loadStripe(stripePublicKey)

export function ElementsProvider(props: {children: ReactElement}) {
  const options: any = {
    mode: 'setup',
    currency: 'bgn',
    paymentMethodCreation: 'manual',
  }
  return (
    <Elements stripe={stripePromise} options={options}>
      {props.children}
    </Elements>
  )
}

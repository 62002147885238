import {Pay} from 'attendee/Billing/Pay'
import {useAttendeeBilling} from 'attendee/Billing/Providers/AttendeeBillingProvider'
import React from 'react'

export default function PayModal() {
  const {payModalOpen, setPayModalOpen} = useAttendeeBilling()

  const handleOpenModal = () => {
    setPayModalOpen(!payModalOpen)
  }

  return (
    <>
      <div
        onClick={handleOpenModal}
        className={`offcanvas__overlay ${payModalOpen ? 'overlay-open' : ''}`}
      ></div>
      <section className={`speaker__popup-area ${payModalOpen ? 'open' : ''}`}>
        <div className="popup__wrapper">
          <div className="popup__title-wrapper">
            <h3 className="popup__title">Изберете начин на плащане</h3>
          </div>
          <div className="popup__input-wrapper">{payModalOpen && <Pay />}</div>
        </div>
      </section>
    </>
  )
}

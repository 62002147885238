import BreadcrumMenu from 'organization/theme/Breadcrum'
import React from 'react'
import {Link} from 'react-router-dom'
import {useOrganizationRoutes} from 'organization/OrganizationRoutes'
import DashboardPageWithoutSideBar from 'organization/theme/DashboardPageWithoutSideBar'
import styled from 'styled-components'
import {useOrganizationEventsList} from 'organization/ListEvents/providers/OrganizationEventsListProvider'
import {ListItem} from 'organization/ListEvents/ListItem'

export const EventsList = () => {
  const routes = useOrganizationRoutes()

  const {events} = useOrganizationEventsList()

  const breadcrumButtons = (
    <div className="breadcrum__button">
      <Link
        to={routes.event.create.root}
        className="breadcrum__btn event__popup-active"
      >
        Създай събитие<i className="fa-regular fa-plus"></i>
      </Link>
    </div>
  )

  return (
    <DashboardPageWithoutSideBar>
      <>
        <BreadcrumMenu title="Моите събития" buttons={breadcrumButtons} />

        <div className="row g-20">
          {events.length > 0 &&
            events?.map((event) => <ListItem event={event} />)}
          {events.length <= 0 && (
            <NoEventsMessage>Няма намерени събития</NoEventsMessage>
          )}
        </div>
      </>
    </DashboardPageWithoutSideBar>
  )
}

const NoEventsMessage = styled.div`
  text-align: center;
`

import {EventModel} from 'event/models/Event'
import React from 'react'
import {Link} from 'react-router-dom'
import {
  organizationEventRoutes,
  useOrganizationRoutes,
} from 'organization/OrganizationRoutes'
import overlay_img from 'assets/img/bg/count-bg.png'
import DateRangeIcon from '@mui/icons-material/DateRange'

export const ListItem = (props: {event: EventModel}) => {
  const {event} = props
  const organizationRoutes = useOrganizationRoutes()
  const routes = organizationEventRoutes(event, organizationRoutes)

  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6" key={23}>
      <Link to={routes.root}>
        <div className="expovent__count-item mb-20">
          <div
            className="expovent__count-thumb include__bg transition-3"
            style={{backgroundImage: `url(${overlay_img})`}}
          ></div>
          <div className="expovent__count-content">
            <h3 className="expovent__count-number">{event.name}</h3>
            <span className="expovent__count-text">
              Организация: {event.organization.name}
            </span>
          </div>
          <div className="expovent__count-icon">
            <i>
              <DateRangeIcon />
            </i>
          </div>
        </div>
      </Link>
    </div>
  )
}

import { organizationApiClient } from 'organization/organizationApiClient'
import React, { useEffect, useState } from 'react'
import { Template } from 'TemplateEditor/template'
import { useRouteOrganizationEventId } from 'utils/lib/url'

export type ExampleTemplate = {
  name: string
  image: string 
  template: Template
}

export interface ExampleTemplatesContextProps {
  exampleTemplates: ExampleTemplate[]
}

export const ExampleTemplatesContext = React.createContext<
  ExampleTemplatesContextProps | undefined
>(undefined)

export default function ExampleTemplatesProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const eventId = useRouteOrganizationEventId()
  const [exampleTemplates, setExampleTemplates] = useState<ExampleTemplate[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(
        `api/event/${eventId}/example-templates`,
      )
  
      setExampleTemplates(response)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  return (
    <ExampleTemplatesContext.Provider
      value={{
        exampleTemplates: exampleTemplates,
      }}
    >
      {props.children}
    </ExampleTemplatesContext.Provider>
  )
}

export function useExampleTemplates() {
  const context = React.useContext(ExampleTemplatesContext)
  if (context === undefined) {
    throw new Error('useExampleTemplates must be used within ExampleTemplatesProvider')
  }
  return context
}

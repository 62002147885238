import React from 'react'
import {ColorInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ColorInput'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'

export type DefaultBlockConfigType = {
  horizontalPadding: number
  verticalPadding: number
  background: string | null
  visible: boolean
  visibility: 'all' | 'desktop' | 'mobile'
}

export const DefaultBlockConfigValues: DefaultBlockConfigType = {
  horizontalPadding: 0,
  verticalPadding: 0,
  background: null,
  visible: false,
  visibility: 'all',
}

export function DefaultBlockConfig(props: {values: any; setFieldValue: any}) {
  const {values, setFieldValue} = props

  return (
    <>
      <ColorInput
        value={values.background ?? ''}
        name={'background'}
        label="Цвят на фона"
        setFieldValue={setFieldValue}
      />
      <SliderInput
        value={values.horizontalPadding}
        name={'horizontalPadding'}
        label="Хоризонтално отстояние"
        setFieldValue={setFieldValue}
        max={500}
        min={0}
        step={1}
      />
      <SliderInput
        value={values.verticalPadding}
        name={'verticalPadding'}
        label="Вертикално отстояние"
        setFieldValue={setFieldValue}
        max={500}
        min={0}
        step={1}
      />
    </>
  )
}

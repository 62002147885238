import React from 'react'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {useTemplate} from 'TemplateEditor/TemplateProvider'
import {getByNestedKeys, removeByNestedKeys} from 'utils/lib/json'
import {ListBlocks} from 'TemplateEditor/Layout/Blocks'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';

export function Config() {
  const {setEditElement, editElement, mobileMode, setMobileMode} = useEditor()
  const {template, updateTemplate} = useTemplate()


  const handleToggleMobileMode= () => setMobileMode(!mobileMode)

  const removeBlock = () => {
    setEditElement(null)
    updateTemplate(removeByNestedKeys(template, editElement))
  }

  return (
    <>
      <div className={'offcanvas__editor info-open'}>
        <div className="offcanvas__wrapper">
          <div
            className="offcanvas__content"
            style={{
              overflow: 'scroll',
              bottom: '0',
              top: '100px',
              width: '290px',
            }}
          >

   <div className={`bd-theme-toggle mb-20 d-block`}>
                  <label
                    className="bd-theme-toggle-main"
                    style={{width: '100%'}}
                    htmlFor="bd-theme-toggler2"
                  >
                    <span
                      className={`bd-theme-toggle-dark ${!mobileMode ? 'active' : ''}`}
                    >
                      <LaptopMacIcon /> Стандартна
                    </span>
                    <input
                      type="checkbox"
                      id="bd-theme-toggler2"
                      checked={!mobileMode}
                      onChange={handleToggleMobileMode}
                    />
                    <i className="bd-theme-toggle-slide"></i>
                    <span
                      className={`bd-theme-toggle-light ${mobileMode ? 'active' : ''}`}
                    >
                      <SmartphoneIcon /> Мобилна
                    </span>
                  </label>
                </div>


            <div style={{display: 'flex', gap: 10}}>
              <button
                className={'input__btn border-red w-100 mb-20'}
                style={{
                  background: 'none',
                  color: '#f87a58',
                  border: '1px solid #f87a58',
                  height: '35px',
                }}
                onClick={() => {
                  setEditElement(null)
                }}
              >
                {`Затвори`}
              </button>
              <button
                className={'input__btn border-red w-100 mb-20'}
                onClick={removeBlock}
                style={{
                  height: '35px',
                }}
              >
                Премахване
              </button>
            </div>
            <Configurator />
          </div>
        </div>
      </div>
    </>
  )
}

function Configurator() {
  const {editElement} = useEditor()
  const {template} = useTemplate()

  const block = getByNestedKeys(template, editElement)

  if (!block) return null

  for (const ListBlock of ListBlocks) {
    if (block.type === ListBlock.typeId) {
      return ListBlock.config()
    }
  }

  return null
}

import {PaymentIntent, PaymentMethod} from '@stripe/stripe-js'
import {Image} from 'organization/ImageSelector'
import {organizationApiClient} from 'organization/organizationApiClient'
import React, {useState} from 'react'
import {toast} from 'react-toastify'

export interface OrganizationBillingContextProps {
  loading: boolean
  paymentMethods: PaymentMethod[]
  getPaymentMethods: () => Promise<void>
  removePaymentMethod: (paymentMethod: string) => Promise<void>
  payments: PaymentIntent[]
  getPayments: () => Promise<void>
  createConfirmationIntent: (confirmationTokenId: string) => Promise<void>
  payModalOpen: boolean
  setPayModalOpen: (set: boolean) => void
  imagesModalOpen: boolean
  setImagesModalOpen: (set: boolean) => void
  setOnImageSelect: (onImageSelect: (image: Image) => void) => void
  imageSelected: (image: Image) => void
  payPayment: (payment: (paymentMethodId: string) => Promise<void>) => void
  selectPaymentMethod: (paymentMethodId: string) => void
}

export const OrganizationBillingContext = React.createContext<
  OrganizationBillingContextProps | undefined
>(undefined)

export default function OrganizationBillingProvider(props: {
  children: React.ReactElement
}) {
  const [payModalOpen, setPayModalOpen] = useState(false)
  const [imagesModalOpen, setImagesModalOpen] = useState(false)
  const apiClient = organizationApiClient()
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [payments, setPayments] = useState<PaymentIntent[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getPaymentMethods = async () => {
    setLoading(true)
    apiClient
      .get('api/organization/payments/payment-methods')
      .then((response) => setPaymentMethods(response))
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const removePaymentMethod = async (paymentMethod: string) => {
    setLoading(true)
    apiClient
      .post(`api/organization/payments/remove-payment-method`, {
        paymentMethod,
      })
      .then(() => {
        setPaymentMethods((current) =>
          current.filter((pMethod) => pMethod.id !== paymentMethod),
        )
        toast.success('Начина на плащане е премахнат.')
      })
      .catch(() => {
        toast.warning('Начина на плащане не може да се премахне.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getPayments = async () => {
    setLoading(true)
    apiClient
      .get('api/organization/payments/payments')
      .then((response) => setPayments(response))
      .finally(() => {
        setLoading(false)
      })
  }

  const createConfirmationIntent = async (confirmationTokenId: string) => {
    setLoading(true)
    return apiClient
      .post('api/organization/payments/create-confirmation-intent', {
        confirmationTokenId: confirmationTokenId,
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [currentPayment, setCurrentPayment] =
    useState<() => (paymentMethodId: string) => Promise<void>>()
  const payPayment = (payment: (paymentMethodId: string) => Promise<void>) => {
    setPayModalOpen(true)
    setCurrentPayment(() => () => payment)
  }

  const selectPaymentMethod = (paymentMethodId: string) => {
    if (!currentPayment) {
      setPayModalOpen(false)
      return
    }

    const payment = currentPayment()

    payment(paymentMethodId).then(() => {
      setPayModalOpen(false)
    })
  }

  const [currentImageSelect, setCurrentImageSelect] =
    useState<() => (image: Image) => void>()

  const imageSelected = (image: Image) => {
    if (!currentImageSelect) return

    const selectImage = currentImageSelect()
    selectImage(image)
  }

  const setOnImageSelect = (onImageSelect: (image: Image) => void) => {
    setCurrentImageSelect(() => () => onImageSelect)
  }

  return (
    <OrganizationBillingContext.Provider
      value={{
        loading,
        paymentMethods,
        getPaymentMethods,
        removePaymentMethod,
        payments,
        getPayments,
        createConfirmationIntent,
        payModalOpen,
        setPayModalOpen,
        payPayment,
        selectPaymentMethod,
        imagesModalOpen,
        setImagesModalOpen,
        imageSelected,
        setOnImageSelect,
      }}
    >
      {props.children}
    </OrganizationBillingContext.Provider>
  )
}

export function useOrganizationBilling() {
  const context = React.useContext(OrganizationBillingContext)
  if (context === undefined) {
    throw new Error(
      'useOrganizationBilling must be used within OrganizationBillingProvider',
    )
  }
  return context
}

import {useAttendeeBilling} from 'attendee/Billing/Providers/AttendeeBillingProvider'
import {Loader} from 'attendee/theme/Loader'
import React, {useEffect} from 'react'

export function Payments() {
  const {payments, getPayments, loading} = useAttendeeBilling()

  useEffect(() => {
    getPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loader />

  return (
    <div className="attendant__wrapper mb-35">
      <table>
        <thead>
          <tr>
            <th>Номер</th>
            <th>Дата</th>
            <th>Сума</th>
            <th>Статус</th>
            <th>Покупка</th>
          </tr>
        </thead>
        <tbody>
          {payments.length === 0 && (
            <tr>
              <td colSpan={5}>Няма направени плащания</td>
            </tr>
          )}
          {payments &&
            payments.map((payment, index) => {
              return (
                <tr key={1}>
                  <td>
                    <div className="attendant__serial">
                      <span> {index + 1} </span>
                    </div>
                  </td>
                  <td>
                    <div className="attendant__date">
                      <span>{payment.created}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span> {(payment.amount / 100).toFixed(2)} лв</span>
                    </div>
                  </td>
                  <td>
                    <div className="attendant__status">
                      {payment.status === 'succeeded' && (
                        <span className={'status__tag bg-green'}>
                          <span>Платено</span>
                        </span>
                      )}
                      {payment.status !== 'succeeded' && (
                        <span className={'status__tag bg-warn'}>
                          <span>Неуспешно</span>
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      <span> {payment.description}</span>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

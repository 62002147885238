import {PaymentIntent, PaymentMethod} from '@stripe/stripe-js'
import {attendeeApiClient} from 'attendee/apiClient'
import React, {useState} from 'react'
import {toast} from 'react-toastify'

export interface AttendeeBillingContextProps {
  loading: boolean
  paymentMethods: PaymentMethod[]
  getPaymentMethods: () => Promise<void>
  removePaymentMethod: (paymentMethod: string) => Promise<void>
  payments: PaymentIntent[]
  getPayments: () => Promise<void>
  createConfirmationIntent: (confirmationTokenId: string) => Promise<void>
  payModalOpen: boolean
  setPayModalOpen: (set: boolean) => void
  payPayment: any
  selectPaymentMethod: any
}

export const AttendeeBillingContext = React.createContext<
  AttendeeBillingContextProps | undefined
>(undefined)

export default function AttendeeBillingProvider(props: {
  children: React.ReactElement
}) {
  const [payModalOpen, setPayModalOpen] = useState(false)
  const apiClient = attendeeApiClient()
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [payments, setPayments] = useState<PaymentIntent[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const getPaymentMethods = async () => {
    setLoading(true)
    apiClient
      .get('api/payments/payment-methods')
      .then((response) => setPaymentMethods(response))
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const removePaymentMethod = async (paymentMethod: string) => {
    setLoading(true)
    apiClient
      .post(`api/payments/remove-payment-method`, {
        paymentMethod,
      })
      .then(() => {
        setPaymentMethods((current) =>
          current.filter((pMethod) => pMethod.id !== paymentMethod),
        )
        toast.success('Начина на плащане е премахнат.')
      })
      .catch(() => {
        toast.warning('Начина на плащане не може да се премахне.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getPayments = async () => {
    setLoading(true)
    apiClient
      .get('api/payments/payments')
      .then((response) => setPayments(response))
      .finally(() => {
        setLoading(false)
      })
  }

  const createConfirmationIntent = async (confirmationTokenId: string) => {
    setLoading(true)
    return apiClient
      .post('api/payments/create-confirmation-intent', {
        confirmationTokenId: confirmationTokenId,
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [currentPayment, setCurrentPayment] =
    useState<() => (paymentMethodId: string) => Promise<void>>()
  const payPayment = (payment: (paymentMethodId: string) => Promise<void>) => {
    setPayModalOpen(true)
    setCurrentPayment(() => () => payment)
  }

  const selectPaymentMethod = (paymentMethodId: string) => {
    setLoading(true)
    
    if (!currentPayment) {
   
      setPayModalOpen(false)
      setLoading(false)
      return
    }

    const payment = currentPayment()

    payment(paymentMethodId).then(() => {
      setPayModalOpen(false)
      setLoading(false)
    })
  }

  return (
    <AttendeeBillingContext.Provider
      value={{
        loading,
        paymentMethods,
        getPaymentMethods,
        removePaymentMethod,
        payments,
        getPayments,
        createConfirmationIntent,
        payModalOpen,
        setPayModalOpen,
        payPayment,
        selectPaymentMethod,
      }}
    >
      {props.children}
    </AttendeeBillingContext.Provider>
  )
}

export function useAttendeeBilling() {
  const context = React.useContext(AttendeeBillingContext)
  if (context === undefined) {
    throw new Error(
      'useAttendeeBilling must be used within AttendeeBillingProvider',
    )
  }
  return context
}

import {UpdateProfileData} from 'attendee/Auth/Interfaces/update_profile'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {ErrorMsg} from 'attendee/Auth/Register'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {profile_update_schema} from 'utils/validation-schema'

export default function ProfileUpdate() {
  const {user, updateProfile} = useAuth()
  const [isBtnDisable, setBtnDisable] = useState(false)

  const {handleSubmit, handleBlur, handleChange, values, errors, touched} =
    useFormik({
      initialValues: {
        first_name: user?.first_name ?? '',
        last_name: user?.last_name ?? '',
        email: user?.email ?? '',
      },
      validationSchema: profile_update_schema,
      onSubmit: async (values: UpdateProfileData) => {
        setBtnDisable(true)
        const result = await updateProfile(values)

        if (result === true) {
          toast.success('Успешна редакция')
        }

        setBtnDisable(false)
      },
    })

  return (
    <form onSubmit={handleSubmit}>
      <div className="singel__input-field mb-15">
        <label className="input__field-text">Име</label>
        <input
          name="first_name"
          type="text"
          placeholder="Вашето име"
          value={values.first_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.first_name && <ErrorMsg errors={errors.first_name} />}
      </div>
      <div className="singel__input-field mb-15">
        <label className="input__field-text">Фамилия</label>
        <input
          name="last_name"
          type="text"
          placeholder="Вашата фамилия"
          value={values.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.last_name && <ErrorMsg errors={errors.last_name} />}
      </div>
      <div className="singel__input-field mb-15 ">
        <label className="input__field-text">Имейл адрес</label>
        <input
          name="email"
          type="email"
          disabled
          placeholder="Вашият имейл адрес"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.email && <ErrorMsg errors={errors.email} />}
      </div>

      <div className="input__btn-wrapper">
        <button
          type="submit"
          disabled={isBtnDisable}
          className={
            isBtnDisable
              ? 'disableBtnStyle w-100 mb-20'
              : 'input__btn w-100 mb-20'
          }
        >
          Запазване
        </button>
      </div>
    </form>
  )
}

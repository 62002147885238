import * as Yup from 'yup'

export const signup_schema = Yup.object().shape({
  first_name: Yup.string().required('Това поле е задължително'),
  last_name: Yup.string().required('Това поле е задължително'),
  email: Yup.string().required('Това поле е задължително'),
  password: Yup.string()
    .required('Това поле е задължително')
    .min(8, 'Паролата трябва да е с минимум 8 символа'),
  password_confirmation: Yup.string().test(
    'passwords-match',
    'Паролите не съвпадат',
    function (value) {
      return value === this.resolve(Yup.ref('password')) || value === null
    },
  ),
})

export const login_schema = Yup.object().shape({
  email: Yup.string().required('Това поле е задължително').email(),
  password: Yup.string()
    .required('Това поле е задължително')
    .min(8, 'Паролата трябва да е с минимум 8 символа'),
})
//forgot schema
export const forgotten_schema = Yup.object().shape({
  email: Yup.string().required('Това поле е задължително').email(),
})

export const password_reset_schema = Yup.object().shape({
  password: Yup.string()
    .required('Това поле е задължително')
    .min(8, 'Паролата трябва да е с минимум 8 символа'),
  password_confirmation: Yup.string().test(
    'passwords-match',
    'Паролите не съвпадат',
    function (value) {
      return value === this.resolve(Yup.ref('password')) || value === null
    },
  ),
})

export const profile_update_schema = Yup.object().shape({
  first_name: Yup.string().required('Това поле е задължително'),
  last_name: Yup.string().required('Това поле е задължително'),
  email: Yup.string().required('Това поле е задължително'),
})

export const password_update_schema = Yup.object().shape({
  old_password: Yup.string().required('Това поле е задължително'),
  password: Yup.string()
    .required('Това поле е задължително')
    .min(8, 'Паролата трябва да е с минимум 8 символа'),
  password_confirmation: Yup.string().test(
    'passwords-match',
    'Паролите не съвпадат',
    function (value) {
      return value === this.resolve(Yup.ref('password')) || value === null
    },
  ),
})

import React from 'react'
import styled from 'styled-components'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import TextFieldsIcon from '@mui/icons-material/TextFields'

export const NormalTextTypeId = 'block-normal-text'

export function NormalText(props: {
  block: NormalTextConfigType
  section: Section
  setEditMode: () => void
  key: string | number
}) {
  const {block, setEditMode} = props

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <EditorText
        block={block}
        dangerouslySetInnerHTML={{__html: block.value}}
      />
    </DefaultBlockHolder>
  )
}

const EditorText = styled.div<{block: NormalTextConfigType}>`
  & > p {
    font-family: ${(props) => props.block.font};
    color: ${(props) => props.block.fontColor};
    font-size: ${(props) => props.block.fontSize}rem;
    line-height: normal;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 575px) {
    & > p {
      font-size: ${(props) => props.block.fontSize * 8}px;
    }
  }
`

export function PreviewNormalText() {
  return (
    <PreviewHolderDiv>
      <TextFieldsIcon />
      <span>Текст</span>
    </PreviewHolderDiv>
  )
}

export type NormalTextConfigType = Block &
  DefaultBlockConfigType & {
    fontColor: string
    font: string
    fontSize: number
    value: string
  }

export function newNormalText(): NormalTextConfigType {
  return {
    ...DefaultBlockConfigValues,
    id: uuidv4(),
    type: NormalTextTypeId,
    fontColor: '#333333',
    value: '<p>Текст</p>',
    fontSize: 2,
    font: 'Roboto',
  }
}

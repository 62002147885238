import Page from 'attendee/theme/Page'
import React from 'react'

export function FullPageLoader() {
  return (
    <Page disableFooter={true}>
      <div id="preloader" style={{position: 'initial', padding: '50vh 0px'}}>
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    </Page>
  )
}

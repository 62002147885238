import React from 'react'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {routes, routesWithValue} from 'routes/routes'
import List from 'organization/List'
import OrganizationsProvider from 'organization/providers/OrganizationsProvider'
import {Organization} from 'organization/models/Organization'
import OrganizationProvider from 'organization/providers/OrganizationProvider'
import {useURLHasOrganizationID} from 'utils/lib/url'
import {EventModel} from 'event/models/Event'
import {EventIndex} from 'organization/Event'
import {EventDesign} from 'organization/Event/Design'
import OrganizationEventProvider from 'organization/Event/OrganizationEventProvider'
import {Billing} from 'organization/Billing'
import OrganizationEventsListProvider from 'organization/ListEvents/providers/OrganizationEventsListProvider'
import {EventsList} from 'organization/ListEvents'
import {CreateEvent} from 'organization/CreateEvent'
import {Settings} from 'organization/Event/Settings'
import {Addons} from 'organization/Event/Addons'
import {Tickets} from 'organization/Event/Tickets'
import {Vision} from 'organization/Event/Vision'
import {Earning} from 'organization/Event/Earning'
import {useOrganizationAuth} from 'organization/Auth/Providers/OrganizationAuthProvider'
import {Login} from 'organization/Auth/Login'
import {Register} from 'organization/Auth/Register'
import EventAttendantsProvider from 'organization/Event/Attendants/EventAttendantsProvider'
import {Attendants} from 'organization/Event/Attendants'
import {ViewAttendant} from 'organization/Event/Attendants/ViewAttendant'
import EventEarningProvider from 'organization/Event/Earning/EventEarningProvider'
import {GoogleCallback} from 'organization/Auth/GoogleCallback'
import {PasswordRecover} from 'organization/Auth/PasswordRecover'
import {Profile} from 'organization/Auth/Profile'
import {Management} from 'organization/Event/Management'
import MeetingProvider from 'organization/Event/MeetingProvider'
import AttendantProvider, {
  Attendant,
} from 'organization/Event/Attendants/ViewAttendant/AttendantProvider'

export function OrganizationRoutes() {
  const {user} = useOrganizationAuth()
  const urlHasOrganizationID = useURLHasOrganizationID()

  if (!user) {
    return (
      <Switch>
        <Route path={routes.organization.login}>
          <Login />
        </Route>
        <Route path={routes.organization.register}>
          <Register />
        </Route>
        <Route path={routes.organization.auth.google}>
          <GoogleCallback />
        </Route>
        <Route path={routes.organization.passwordRecover}>
          <PasswordRecover />
        </Route>
        <Redirect to={routes.organization.login} />
      </Switch>
    )
  }

  if (!urlHasOrganizationID) {
    return (
      <Switch>
        <Route path={routes.organization.profile}>
          <Profile />
        </Route>
        <Route path={routes.organization.billing}>
          <Billing />
        </Route>
        <Route path={routes.organization.list}>
          <OrganizationsProvider>
            <List />
          </OrganizationsProvider>
        </Route>
        <Redirect to={routes.organization.list} />
      </Switch>
    )
  }

  return (
    <OrganizationProvider>
      <Switch>
        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].attendant[
              ':attendantId'
            ].view
          }
        >
          <OrganizationEventProvider>
            <AttendantProvider>
              <ViewAttendant />
            </AttendantProvider>
          </OrganizationEventProvider>
        </Route>
        <Route path={routes.organization[':organizationId'].events}>
          <OrganizationEventsListProvider>
            <EventsList />
          </OrganizationEventsListProvider>
        </Route>
        <Route path={routes.organization[':organizationId'].event.create.root}>
          <CreateEvent />
        </Route>

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].attendants
          }
        >
          <OrganizationEventProvider>
            <EventAttendantsProvider>
              <Attendants />
            </EventAttendantsProvider>
          </OrganizationEventProvider>
        </Route>

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].management
          }
        >
          <OrganizationEventProvider>
            <MeetingProvider>
              <Management />
            </MeetingProvider>
          </OrganizationEventProvider>
        </Route>

        <Route
          path={routes.organization[':organizationId'].event[':eventId'].design}
        >
          <OrganizationEventProvider>
            <EventDesign />
          </OrganizationEventProvider>
        </Route>
        <Route
          path={routes.organization[':organizationId'].event[':eventId'].vision}
        >
          <OrganizationEventProvider>
            <Vision />
          </OrganizationEventProvider>
        </Route>
        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].tickets
          }
        >
          <OrganizationEventProvider>
            <Tickets />
          </OrganizationEventProvider>
        </Route>
        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].earning
          }
        >
          <OrganizationEventProvider>
            <EventEarningProvider>
              <Earning />
            </EventEarningProvider>
          </OrganizationEventProvider>
        </Route>
        <Route
          path={routes.organization[':organizationId'].event[':eventId'].addons}
        >
          <OrganizationEventProvider>
            <Addons />
          </OrganizationEventProvider>
        </Route>
        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].settings
          }
        >
          <OrganizationEventProvider>
            <Settings />
          </OrganizationEventProvider>
        </Route>
        <Route
          path={routes.organization[':organizationId'].event[':eventId'].root}
        >
          <OrganizationEventProvider>
            <EventIndex />
          </OrganizationEventProvider>
        </Route>

        <Redirect to={routes.organization.list} />
      </Switch>
    </OrganizationProvider>
  )
}

export function useOrganizationRoutes() {
  const id = useParamOrganizationId()
  return routesWithValue(
    ':organizationId',
    String(id),
    routes.organization[':organizationId'],
  )
}

export function useParamOrganizationId() {
  const {organizationId} = useParams<{organizationId: string}>()
  return organizationId
}

export function useOrganizationEventRoutes() {
  const id = useParamEventId()
  const routes = useOrganizationRoutes()
  return routesWithValue(':eventId', String(id), routes.event[':eventId'])
}

export function useParamEventId() {
  const {eventId} = useParams<{eventId: string}>()
  return eventId
}

export function organizationRoutes(organization: Pick<Organization, 'id'>) {
  return routesWithValue(
    ':organizationId',
    String(organization.id),
    routes.organization[':organizationId'],
  )
}

export function organizationEventRoutes(
  event: Pick<EventModel, 'id'>,
  organizationRoutes: any,
) {
  return routesWithValue(
    ':eventId',
    String(event.id),
    organizationRoutes.event[':eventId'],
  )
}

export function organizationEventAttendeeRoutes(
  attendant: Pick<Attendant, 'id'>,
  organizationEventRoutes: any,
) {
  return routesWithValue(
    ':attendantId',
    String(attendant.id),
    organizationEventRoutes.attendant[':attendantId'],
  )
}

import {Attendant} from 'organization/Event/Attendants/ViewAttendant/AttendantProvider'
import {organizationApiClient} from 'organization/organizationApiClient'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useRouteOrganizationEventId} from 'utils/lib/url'

export interface EventAttendantsContextProps {
  attendants: Attendant[]
  loading: boolean
  page: number
  totalPages: number
  changePage: (e: any, page: number) => void
  searchAtendant: (email: string) => void
  addAttendant: (email: string) => Promise<string | boolean>
}

export const EventAttendantsContext = React.createContext<
  EventAttendantsContextProps | undefined
>(undefined)

export default function EventAttendantsProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [attendants, setAttendants] = useState<Attendant[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const eventId = useRouteOrganizationEventId()

  useEffect(() => {
    get()
  }, [])

  const get = async (getPage?: number, searchEmail?: string) => {
    const selectedPage = getPage ?? page
    const search = searchEmail ?? ''
    try {
      const response = await apiClient.get(
        `api/event/${eventId}/attendants?page=${selectedPage}&searchEmail=${search}`,
      )
      setAttendants(response.data)
      setTotalPages(response.last_page)
      setPage(response.current_page)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  const changePage = (e: any, toPage: number) => {
    setPage(toPage)
    get(toPage)
  }

  const addAttendant = async (email: string) => {
    const result = await apiClient
      .post(`api/event/${eventId}/attendant`, {email})
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const searchAtendant = async (email: string) => {
    return get(1, email)
  }

  return (
    <EventAttendantsContext.Provider
      value={{
        attendants,
        loading,
        page,
        changePage,
        totalPages,
        addAttendant,
        searchAtendant,
      }}
    >
      {props.children}
    </EventAttendantsContext.Provider>
  )
}

export function useEventAttendants() {
  const context = React.useContext(EventAttendantsContext)
  if (context === undefined) {
    throw new Error(
      'useEventAttendants must be used within EventAttendantsProvider',
    )
  }
  return context
}

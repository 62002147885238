import moment from 'moment'
import {useAttendant} from 'organization/Event/Attendants/ViewAttendant/AttendantProvider'
import EventPage from 'organization/Event/EventPage'
import {JoinButton} from 'organization/Event/Management'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React from 'react'

export const ViewAttendant = () => {
  const {attendant} = useAttendant()

  const handleJoin = async () => {
    window.open(
      attendant?.join_link?.join_link,
      '_blank',
      'noopener,noreferrer',
    )
  }

  return (
    <EventPage>
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Преглед на участник" />

          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper">
                {/* create event form  */}

                <div className="create__event-main pt-25">
                  <div className="event__left-box">
                    <div className="event__meta-time">
                      <ul>
                        <li>
                          <span> Име: </span>
                          {attendant?.attendee.first_name}
                        </li>
                        <li>
                          <span> Фамилия: </span>
                          {attendant?.attendee.last_name}
                        </li>
                        <li>
                          <span> Имейл: </span>
                          {attendant?.attendee.email}
                        </li>
                        <li>
                          <span> Дата на покупка: </span>
                          {attendant?.created_at
                            ? moment(attendant.created_at).format(
                                'DD MMMM YYYY HH:mm',
                              )
                            : 'Няма информация'}
                        </li>

                        <li>
                          <span>Номер на билет: </span>
                          {attendant?.id}
                        </li>
                        <li>
                          <span>Цена на билет: </span>
                          {attendant?.price} лв.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="event__right-box">
                    <div className="row g-20">
                      <h3>Вход чрез билета на участника </h3>

                      <div className="event__input mb-15 pt-15">
                        <label className="input__field-text">
                          Линк за присъединяване към събитието <br />{' '}
                          <i>
                            <small>Може да бъде споделен с участника</small>
                          </i>
                        </label>

                        <textarea disabled={true}>
                          {attendant?.join_link?.join_link}
                        </textarea>
                      </div>

                      <div>
                        <JoinButton
                          type="button"
                          onClick={handleJoin}
                          className={'input__btn w-100 '}
                        >
                          Вход
                        </JoinButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

import React from 'react'
import styled from 'styled-components'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {BlockElements} from 'TemplateEditor/Layout/Blocks'

export function DefaultBlockHolder(props: {
  children: JSX.Element
  key: number | string
  block: BlockElements
  onClick: () => void
}) {
  const {editMode} = useEditor()

  if (!editMode && !props.block.visible) {
    return null
  }

  return (
    <Holder block={props.block} onClick={props.onClick}>
      {props.children}
    </Holder>
  )
}

const Holder = styled.div<{block: BlockElements}>`
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (!props.block.visible ? 'border: 2px red solid' : '')};
  padding: ${(props) => props.block.verticalPadding}px
    ${(props) => props.block.horizontalPadding}px;
  background: ${(props) => props.block.background};
`

import React, {useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import {ButtonOneConfigType} from 'TemplateEditor/Layout/Blocks/ButtonOne/ButtonOne'

export function LinkInput(props: {
  value: string
  type: ButtonOneConfigType['linkType']
  setFieldValue: (field: string, value: string) => void
  label: string
  name: string
  typeName: string
}) {
  const {value, setFieldValue, name, typeName, type} = props
  const [linkType, setLinkType] =
    useState<ButtonOneConfigType['linkType']>(type)

  useEffect(() => {
    setLinkType(type)
  }, [type])

  const handleOnChange = (value: string) => {
    setFieldValue(name, value)
    setFieldValue(typeName, linkType)
  }

  const handleToggleInnerLink = () =>
    setLinkType((curr) => (curr === 'internal' ? 'external' : 'internal'))

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>
      <div className={`bd-theme-toggle mb-20 d-block`}>
        <label
          className="bd-theme-toggle-main"
          style={{width: '100%'}}
          htmlFor="bd-theme-toggler"
        >
          <span
            className={`bd-theme-toggle-dark ${linkType === 'internal' ? 'active' : ''}`}
          >
            Вътрешен
          </span>
          <input
            type="checkbox"
            id="bd-theme-toggler"
            checked={linkType === 'internal'}
            onChange={handleToggleInnerLink}
          />
          <i className="bd-theme-toggle-slide"></i>
          <span
            className={`bd-theme-toggle-light ${linkType === 'external' ? '' : 'active'}`}
          >
            Външен
          </span>
        </label>
      </div>
      {linkType === 'internal' && (
        <Form.Select
          onChange={(e) => {
            handleOnChange(e.target.value)
          }}
          className="form-control"
          value={type === 'internal' ? value : ''}
        >
          <option value=""></option>
          <option value="main_stage">Към основна стая</option>
        </Form.Select>
      )}

      {linkType === 'external' && (
        <Form.Control
          type="url"
          onChange={(e) => {
            handleOnChange(e.target.value)
          }}
          value={type === 'external' ? value : ''}
        />
      )}
    </Form.Group>
  )
}

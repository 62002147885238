import {EventModel} from 'event/models/Event'
import {SaveEventData} from 'organization/Event/Settings/SaveEvent'
import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useRouteOrganizationEventId} from 'utils/lib/url'

export interface OrganizationEventContextProps {
  event: EventModel | null
  updateEventTemplate: (eventTemplate: EventModel['template']) => void
  updateEvent: (data: SaveEventData) => Promise<string | boolean>
  makeEventPublic: () => Promise<string | boolean>
  endEvent: () => Promise<string | boolean>
}

export const OrganizationEventContext = React.createContext<
  OrganizationEventContextProps | undefined
>(undefined)

export default function OrganizationEventProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [event, setEvent] = useState<EventModel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const eventId = useRouteOrganizationEventId()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(`api/organization/event/${eventId}`)
      setEvent(response)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  const updateEventTemplate = async (eventTemplate: EventModel['template']) => {
    try {
      await apiClient.post(`api/event/${eventId}/template`, {
        template: JSON.stringify(eventTemplate),
      })
    } catch (error: any) {
      console.log(error)
    }
  }

  const updateEvent = async (data: SaveEventData) => {
    const result = await apiClient
      .post(`api/event/${eventId}/update`, data)
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    if (result) return true

    return false
  }

  const makeEventPublic = async () => {
    const result = await apiClient
      .post(`api/event/${eventId}/make-public`)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  const endEvent = async () => {
    const result = await apiClient
      .post(`api/event/${eventId}/end`)
      .then(get)
      .then(() => {
        return true
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })

    return result
  }

  return (
    <OrganizationEventContext.Provider
      value={{
        event,
        updateEventTemplate,
        updateEvent,
        makeEventPublic,
        endEvent,
      }}
    >
      {loading && <FullPageLoader />}
      {!loading && props.children}
    </OrganizationEventContext.Provider>
  )
}

export function useOrganizationEvent() {
  const context = React.useContext(OrganizationEventContext)
  if (context === undefined) {
    throw new Error(
      'useOrganizationEvent must be used within OrganizationEventProvider',
    )
  }
  return context
}

import React from 'react'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {DefaultBlockConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {SpacerConfigType} from 'TemplateEditor/Layout/Blocks/Spacer/Spacer'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'
import {DefaultBlockTopConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockTopConfig'

export function SpacerConfig() {
  const {handleSubmit, values, setFieldValue} =
    useFormicForm<SpacerConfigType>()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button className={'input__btn w-100 mb-20'} type="submit">
          Запазване
        </button>

        <DefaultBlockTopConfig values={values} setFieldValue={setFieldValue} />

        <SliderInput
          value={values.height}
          name={'height'}
          label="Височина"
          setFieldValue={setFieldValue}
          max={100}
          min={5}
          step={1}
        />

        <DefaultBlockConfig values={values} setFieldValue={setFieldValue} />
      </form>
    </>
  )
}

import Page from 'attendee/theme/Page'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export function Info() {
  return (
    <Page>
    <>
    <section className="contact_section dark_light_class pt-55 pb-150">
        <div className="container">
          <h2 className="section__title mb-35">
            Какво е <span className="text__highlight"> Eventa.tv</span>
          </h2>

          <StyledSection className="pl-30 pr-30 pt-30 pb-30">
            Онлайн платформа, чрез която малкия и среден бизнес може бързо и
            лесно да организира онлайн събитие.
            <br />
            Платформата включва всичко необходимо, а именно:
            <br />
            <br />
            ✅ Автоматично създаване и организиране на стаи за участниците
            <br />
            ✅ Възможност на участниците да предават звук и видео по време на
            събитието
            <br />
            ✅ Максимален брой на участници до 50 000
            <br />
            ✅ Конструктор на собствена страница
            <br />
            ✅ Модул за продажба на билети
            <br />
            ✅ Лесна комуникация с участници
            <br />
            ✅ Прозрачно отчитане на печалбата
            <br />
            ✅ Минимална комисионна <br />
          </StyledSection>
        </div>
      </section>

      <section className="details_section dark_light_class blog_details section_space_lg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="blog_responsive">
                           
                           


                            <>
            <div className="details_content">
             
                <h2 className="details_item_title">Онлайн платформа, чрез която малкия и среден бизнес може бързо и
                лесно да организира онлайн събитие.</h2>
                <p>
                    Some mistakenly believe that because you’re offering a
                    certification it must be generalized and meant to give anyone
                    a wide range of digital skills. But the benefits of continuous
                    This is also a flexible form of learning. You may have a suite
                    of microlearning courses. employees who feel that their skills
                    are not being put to good use in their current job Choose
                    which ones are important for any specific role within your
                    company. For example, marketers may need social media training
                    so they can use.
                </p>
                <p>
                    Investing in offering those paths early on can help you retain
                    staff instead of letting them go. After all, this is the era
                    of hybrid and remote working so flexibility and career
                    advancement. employees who feel that their skills are not
                    being put to good use in their current job
                </p>
                <blockquote cite="/blog">
                    <h3 className="blockquote_content">
                        “You have to be burning with an idea, or a problem, or a
                        wrong that you want to right. If you’re not passionate
                        enough from the start,never stick it out.”
                    </h3>
                    <span className="author_name">Steve Jobs</span>
                </blockquote>
                <p>
                    Some of the employees in your workforce are already
                    digitally-savvy and actively pursue new training and in-demand
                    skills on their own. These employees are valuable, but
                    research demonstrates that you may lose them if you don’t
                    allow them the opportunity to improve their skills further.
                    Should be possible as long as the training is available to
                    upskill employees in the required skills.
                </p>
                <h3 className="details_item_info_title">
                    Update Your Experts Marketing Tools
                </h3>
                <p>
                    Reskilling and digital upskilling While you may be focusing on
                    updating employees who are behind, your HR or L&D team needs
                    to make sure to nurture your most expert staff as well. This
                    will help boost loyalty and sharpen skills for any specific
                    role.
                </p>
                <p>
                    Skills are not being put to good use in their current job
                    Choose which ones are important for any specific role within
                    your company. For example, marketers may need social media
                    training so they can use.
                </p>
                <ul className="icon_list unordered_list_block">
                    <li>
                        <span className="list_item_icon">
                            <i className="fas fa-check"></i>
                        </span>
                        <span className="list_item_text">
                            The {"company’s"} training initiatives reached 210,000
                            employees in 2023
                        </span>
                    </li>
                    <li>
                        <span className="list_item_icon">
                            <i className="fas fa-check"></i>
                        </span>
                        <span className="list_item_text">
                            Created a culture of continuous learning and skills
                            development
                        </span>
                    </li>
                    <li>
                        <span className="list_item_icon">
                            <i className="fas fa-check"></i>
                        </span>
                        <span className="list_item_text">
                            Mature digital organization is essential in {"today’s"}
                            technologically driven world.
                        </span>
                    </li>
                </ul>
                <div className="row mb-4">
                    <div className="col col-lg-6">
                        <div className="video_widget">
                            <img
                                src={""}
                                style={{ width: "100%", height: "100%" }}
                                alt="Paradox Video Poster Image"
                            />
                         
                        </div>
                    </div>
                    <div className="col col-lg-6">
                        <div className="image_wrap">
                            <img
                                src={""}
                                style={{ width: "100%", height: "100%" }}
                                alt="Paradox Blog Details Image"
                            />
                        </div>
                    </div>
                </div>
                <h3 className="details_item_info_title">
                    workforce to drive loyalty
                </h3>
                <p>
                    10 times more likely to be looking for a new job than those
                    who feel that their skills are being put to good use. This
                    shows how important valuing your staff is to drive retention
                    and keep talent within your business. The same report found
                    that the top three focus areas of learning and development
                    (L&D) programs in 2022 are leadership and development,
                    upskilling.
                </p>
                <p>
                    Reskilling and digital upskilling While you may be focusing on
                    updating employees who are behind, your HR or L&D team needs
                    to make sure to nurture your most expert staff as well. This
                    will help boost loyalty and sharpen skills.
                </p>
               
            </div>
        </>

                            <div className="post_admin">
                                <div className="admin_image">
                                    <img
                                        src={"avatar_5"}
                                        style={{ width: "auto", height: "auto" }}
                                        alt="Paradox Blog Post Admin Image"
                                    />
                                </div>
                                <div className="admin_content">
                                    <h3 className="admin_name">Karen L. Head</h3>
                                    <span className="admin_designation">Digital Marketer</span>
                                    <p className="mb-0">
                                        A Content Writer is a professional who writes informative
                                        and engaging articles to help brands showcase their
                                        products.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            </>
    </Page>
  )
}

export const StyledSection = styled.div`
  border-radius: 5px;
  background: #f5f5f5;
`

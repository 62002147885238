import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

export function useRouteEventId() {
  const location = useLocation()
  const id = location.pathname.split('/')[2]

  try {
    return parseInt(id)
  } catch {
    return null
  }
}

export function useRouteOrganizationId() {
  const location = useLocation()
  // Parse id out of URL. example location: '/organization/35',
  // which would split to ['','organization','35'].
  const id = location.pathname.split('/')[2]

  try {
    return parseInt(id)
  } catch {
    return null
  }
}

export function useIsInOrganizationRoutes() {
  const location = useLocation()
  // Parse id out of URL. example location: '/organization/35',
  // which would split to ['','organization','35'].
  const pathName = location.pathname.split('/')[1]

  return pathName === 'organization'
}

export function useIsEventRoutes() {
  const location = useLocation()
  const pathName = location.pathname.split('/')[1]

  return pathName === 'event'
}

export function useURLHasOrganizationID() {
  const location = useLocation()
  // Parse id out of URL. example location: '/organization/35',
  // which would split to ['','organization','35'].
  const pathName = location.pathname.split('/')[1]
  const id = location.pathname.split('/')[2]

  return pathName === 'organization' && parseInt(id) > 0
}

export function useRouteOrganizationEventId() {
  const location = useLocation()
  const id = location.pathname.split('/')[4]

  try {
    return parseInt(id)
  } catch {
    return null
  }
}

export function useRouteOrganizationAtendantId() {
  const location = useLocation()
  const id = location.pathname.split('/')[6]

  try {
    return parseInt(id)
  } catch {
    return null
  }
}

export type QueryParams = Record<string, string | undefined>

export function useQueryParams() {
  const {search} = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)

    const result: QueryParams = {}

    for (const [key, val] of params.entries()) {
      result[key] = val
    }

    return result
  }, [search])
}

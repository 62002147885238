import {ListSections} from 'TemplateEditor/Layout/Sections/Section'
import React from 'react'
import {Draggable, Droppable} from 'react-beautiful-dnd'

export function SectionsList() {
  return (
    <Droppable
      isDropDisabled={true}
      droppableId="default-sections"
      type="section"
    >
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {ListSections.map((listSection, index) => {
            return (
              <Draggable
                draggableId={listSection.typeId}
                index={index}
                key={index}
              >
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      {listSection.preview()}
                    </div>
                  )
                }}
              </Draggable>
            )
          })}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

import React, { useState } from 'react'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import ExampleTemplatesProvider from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplatesProvider'
import ExampleTemplateModal from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplateModal'
 
export function ExampleTemplates() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleOpen = () => {
    setIsOpen((cur) => !cur)
  }

  return (
   <>
     

                  <button
                  type="button"
                  className={'input__btn w-100 mt-30'}
                  onClick={toggleOpen}
                >
                     <LibraryAddIcon /> Примерни дизайни
                </button>
                {isOpen && <>
                  <ExampleTemplatesProvider>
                      <ExampleTemplateModal isOpen={isOpen} toggleOpen={toggleOpen}/>
                  </ExampleTemplatesProvider>
                  
                </>}
   </>
  )
}

import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import React, {ReactElement} from 'react'
import {Redirect} from 'react-router-dom'
import {routes} from 'routes/routes'

export function AuthReqired(props: {children: ReactElement}) {
  const {user} = useAuth()

  if (!user) return <Redirect to={routes.login} />

  return props.children
}

import EventPage from 'organization/Event/EventPage'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React from 'react'
import ExtensionIcon from '@mui/icons-material/Extension'

export const Addons = () => {
  return (
    <EventPage>
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Добавки" />

          <div className="event__venue-area">
            <div className="row">
              <div key={1} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="event__venue-card mb-20">
                  <div className="event__venue-inner">
                    <div className="event__venue-content">
                      <div className="event__venue-meta">
                        <div className="news__meta-status">
                          <span>
                            <i>
                              <ExtensionIcon />
                            </i>
                          </span>
                          <span>Голяма стая</span>
                        </div>
                      </div>
                      <h4 className="event__venue-title">Цена: 217 лв.</h4>
                    </div>
                    <div className="event__venue-thumb">
                      <div className="pricing__item mb-30">
                        <div className="pricing__item-header">
                          <div>
                            <span className="pricing__item-title">
                              Голяма стая
                            </span>
                          </div>
                        </div>
                        <div className="pricing__item-content">
                          <ul>
                            <li>
                              <i className="flaticon-comment"></i> Увеличаване
                              на капацитета на събитието до 1000 места.
                            </li>
                          </ul>
                        </div>
                        <div className="pricing__btn-wrap">
                          <button
                            className="disableBtnStyle w-100"
                            type="submit"
                          >
                            При запитване
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div key={1} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="event__venue-card mb-20">
                  <div className="event__venue-inner">
                    <div className="event__venue-content">
                      <div className="event__venue-meta">
                        <div className="news__meta-status">
                          <span>
                            <i>
                              <ExtensionIcon />
                            </i>
                          </span>
                          <span>Средна стая</span>
                        </div>
                      </div>
                      <h4 className="event__venue-title">Цена: 154 лв.</h4>
                    </div>
                    <div className="event__venue-thumb">
                      <div className="pricing__item mb-30">
                        <div className="pricing__item-header">
                          <div>
                            <span className="pricing__item-title">
                              Средна стая
                            </span>
                          </div>
                        </div>
                        <div className="pricing__item-content">
                          <ul>
                            <li>
                              <i className="flaticon-comment"></i> Увеличаване
                              на капацитета на събитието до 500 места.
                            </li>
                          </ul>
                        </div>
                        <div className="pricing__btn-wrap">
                          <button
                            className="disableBtnStyle w-100"
                            type="submit"
                          >
                            При запитване
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div key={2} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="event__venue-card mb-20">
                  <div className="event__venue-inner">
                    <div className="event__venue-content">
                      <div className="event__venue-meta">
                        <div className="news__meta-status">
                          <span>
                            <i>
                              <ExtensionIcon />
                            </i>
                          </span>
                          <span>Малък пакет</span>
                        </div>
                      </div>
                      <h4 className="event__venue-title">Цена: 25 лв.</h4>
                    </div>
                    <div className="event__venue-thumb">
                      <div className="pricing__item mb-30">
                        <div className="pricing__item-header">
                          <div>
                            <span className="pricing__item-title">
                              Малък пакет
                            </span>
                          </div>
                        </div>
                        <div className="pricing__item-content">
                          <ul>
                            <li>
                              <i className="flaticon-comment"></i> Добавя още 10
                              ръчно добавени участника.
                            </li>
                          </ul>
                        </div>
                        <div className="pricing__btn-wrap">
                          <button
                            className="disableBtnStyle w-100"
                            type="submit"
                          >
                            При запитване
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

import DashbordSidebar from 'organization/theme/Sidebar'
import React from 'react'
import {Link} from 'react-router-dom'
import small_logo from 'assets/img/logo/logo-small.svg'
import {useOrganizationEventRoutes} from 'organization/OrganizationRoutes'
import SettingsIcon from '@mui/icons-material/Settings'
import ExtensionIcon from '@mui/icons-material/Extension'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PersonIcon from '@mui/icons-material/Person'
import {routes as defaultRoutes} from 'routes/routes'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
export const EventSidebar = () => {
  const routes = useOrganizationEventRoutes()

  const Menu = (
    <>
      <div className="logo-details">
        <span>
          <Link to={defaultRoutes.organization.list}>
            <img
              style={{width: '100%', height: '100%', maxHeight: '50px'}}
              className="logo__white"
              src={small_logo}
              alt="logo not found"
            />
          </Link>
        </span>
        <span>
          <Link to={defaultRoutes.organization.list}>
            <img
              style={{width: '180px', height: 'auto'}}
              className="log__smnall"
              src={process.env.PUBLIC_URL + '/logo-dark.svg'}
              alt="logo not found"
            />
          </Link>
        </span>
      </div>
      <div className="sidebar__inner simple-bar">
        <div className="dlabnav">
          <ul className="metismenu" id="menu">
            <li>
              <Link to={routes.root}>
                <i>
                  <QueryStatsIcon />
                </i>
                <span className="nav-text">Статистика</span>
              </Link>
            </li>
            <li>
              <Link to={routes.management}>
                <i>
                  <PlayCircleIcon />
                </i>
                <span className="nav-text">Управление</span>
              </Link>
            </li>
            <li>
              <Link to={routes.attendants}>
                <i>
                  <PersonIcon />
                </i>
                <span className="nav-text">Участници</span>
              </Link>
            </li>

            <li>
              <Link to={routes.design}>
                <i>
                  <DesignServicesIcon />
                </i>
                <span className="nav-text">Дизайн</span>
              </Link>
            </li>
            <li>
              <Link to={routes.vision}>
                <i>
                  <RemoveRedEyeIcon />
                </i>
                <span className="nav-text">Визия</span>
              </Link>
            </li>
            <li>
              <Link to={routes.tickets}>
                <i>
                  <ConfirmationNumberIcon />
                </i>
                <span className="nav-text">Билети</span>
              </Link>
            </li>
            <li>
              <Link to={routes.earning}>
                <i>
                  <PointOfSaleIcon />
                </i>
                <span className="nav-text">Печалба</span>
              </Link>
            </li>
            <li>
              <Link to={routes.addons}>
                <i>
                  <ExtensionIcon />
                </i>
                <span className="nav-text">Добавки</span>
              </Link>
            </li>
            <li>
              <Link to={routes.settings}>
                <i>
                  <SettingsIcon />
                </i>
                <span className="nav-text">Настройки</span>
              </Link>
            </li>
          </ul>

          <div className="sidebar__copyright">
            <p>Всички права запазени @ Atomica Group 2024</p>
          </div>
        </div>
      </div>
    </>
  )

  return <DashbordSidebar menu={Menu} />
}

import React from 'react'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {ColorInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ColorInput'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'
import { SwitchInput } from 'TemplateEditor/Layout/Blocks/ConfigInputs/SwitchInput'
import {useTemplate} from 'TemplateEditor/TemplateProvider'
import {removeByNestedKeys} from 'utils/lib/json'

export type SectionConfigType = {
  horizontalPadding: number
  verticalPadding: number
  background: string | null
  borderColor: string | null
  borderWidth: number
  borderRadius: number
  revertMobile: boolean
}

export function SectionConfig() {
  const {setEditElement, editElement} = useEditor()
  const {template, updateTemplate} = useTemplate()

  const {handleSubmit, values, setFieldValue} =
    useFormicForm<SectionConfigType>()

  const removeBlock = () => {
    updateTemplate(removeByNestedKeys(template, editElement))
    setEditElement(null)
  }

  return (
    <>
      <div className={'offcanvas__editor info-open'}>
        <div className="offcanvas__wrapper">
          <div
            className="offcanvas__content"
            style={{
              overflow: 'scroll',
              bottom: '0',
              top: '100px',
              width: '290px',
            }}
          >
            <div style={{display: 'flex', gap: 10}}>
              <button
                className={'input__btn border-red w-100 mb-20'}
                style={{
                  background: 'none',
                  color: '#f87a58',
                  border: '1px solid #f87a58',
                  height: '35px',
                }}
                onClick={() => {
                  setEditElement(null)
                }}
              >
                {`Затвори`}
              </button>
              <button
                className={'input__btn border-red w-100 mb-20'}
                style={{height: '35px'}}
                onClick={removeBlock}
              >
                Премахване
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <button className={'input__btn w-100 mb-20'} type="submit">
                Запазване
              </button>

              <ColorInput
                value={values.background ?? '#ffffff'}
                name={'background'}
                label="Цвят на фона"
                setFieldValue={setFieldValue}
              />

              <SliderInput
                value={values.horizontalPadding}
                name={'horizontalPadding'}
                label="Хоризонтално отстояние"
                setFieldValue={setFieldValue}
                max={500}
                min={0}
                step={1}
              />
              <SliderInput
                value={values.verticalPadding}
                name={'verticalPadding'}
                label="Вертикално отстояние"
                setFieldValue={setFieldValue}
                max={500}
                min={0}
                step={1}
              />
              <ColorInput
                value={values.borderColor ?? '#ffffff'}
                name={'borderColor'}
                label="Цвят на границите"
                setFieldValue={setFieldValue}
              />

              <SliderInput
                value={values.borderWidth}
                name={'borderWidth'}
                label="Дебелина на границите"
                setFieldValue={setFieldValue}
                max={50}
                min={0}
                step={1}
              />

              <SliderInput
                value={values.borderRadius}
                name={'borderRadius'}
                label="Радиус на границите"
                setFieldValue={setFieldValue}
                max={100}
                min={0}
                step={1}
              />
               <SwitchInput
                value={values.revertMobile ?? false}
                name={'revertMobile'}
                label="Размени при мобилна"
                setFieldValue={setFieldValue}
              />

            </form>
          </div>
        </div>
      </div>
    </>
  )
}

import {useOrganizations} from 'organization/providers/OrganizationsProvider'
import overlay_img from 'assets/img/bg/count-bg.png'
import React from 'react'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import {Link} from 'react-router-dom'
import {organizationRoutes} from 'organization/OrganizationRoutes'
import DashboardPageWithoutSideBar from 'organization/theme/DashboardPageWithoutSideBar'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'

export default function List() {
  const {organizations} = useOrganizations()

  return (
    <DashboardPageWithoutSideBar>
      <>
        <BreadcrumMenu title="Свързани организации" />

        <div className="row g-20">
          {organizations?.map((organization) => {
            return (
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6" key={23}>
                <Link to={organizationRoutes(organization).events}>
                  <div className="expovent__count-item mb-20">
                    <div
                      className="expovent__count-thumb include__bg transition-3"
                      style={{backgroundImage: `url(${overlay_img})`}}
                    ></div>
                    <div className="expovent__count-content">
                      <h3 className="expovent__count-number">
                        {organization.name}
                      </h3>
                      <span className="expovent__count-text">
                        Притежател: {organization.owner?.first_name}{' '}
                        {organization.owner?.last_name}
                      </span>
                    </div>
                    <div className="expovent__count-icon">
                      <i>
                        {' '}
                        <CorporateFareIcon />
                      </i>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </>
    </DashboardPageWithoutSideBar>
  )
}

import React, {useEffect} from 'react'
import {BrowserRouter, useLocation} from 'react-router-dom'
import {AllRoutes} from './routes/routes'
import 'assets/styles.scss'
import {ToastContainer} from 'react-toastify'
import {ElementsProvider} from 'stripe/ElementsProvider'

if (typeof window !== 'undefined') {
  require('bootstrap/dist/js/bootstrap')
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ElementsProvider>
          <AllRoutes />
        </ElementsProvider>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App

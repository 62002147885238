import Axios from 'axios'

export const attendeeAccessTokenkey = 'attendee_access_token'

export function attendeeApiClient() {
  const get = async (url: string) => {
    const res = await axios().get(url)
    return res.data
  }

  const post = async (url: string, data?: any) => {
    const res = await axios().post(url, data)
    return res.data
  }

  return {
    get,
    post,
    delete: async (url: string, data?: any) => {
      const res = await axios().delete(url, data)
      return res.data
    },
  }
}

function getAccessToken() {
  return localStorage.getItem(attendeeAccessTokenkey)
}

function axios() {
  const instance = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data;',
      Authorization: 'Bearer ' + getAccessToken(),
    },
  })

  return instance
}

import {Header} from 'organization/theme/Header'
import Modals from 'organization/theme/Modals'
import React from 'react'

export default function DashboardPageWithoutSideBar(props: {
  children: React.ReactElement
  disableModals?: boolean
  modals?: React.ReactElement
}) {
  return (
    <main className={`body-area bd-theme-light pt-80`}>
      {props.disableModals ?? <Modals modals={props.modals} />}
      <div className="page__full-wrapper">
        <Header fullWidth={true} />
        <div className="container">{props.children}</div>
      </div>
    </main>
  )
}

import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {routes} from 'routes/routes'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import LogoutIcon from '@mui/icons-material/Logout'
import Avatar from 'react-avatar'

export const ProfileLinks = () => {
  const {logout, user} = useAuth()

  const [userDropdown, setuserDropdown] = useState<boolean>(false)

  const handleShowuserDrowdown = () => {
    setuserDropdown(!userDropdown)
  }

  return (
    <>
      <div className="nav-item p-relative">
        <button id="userportfolio" onClick={handleShowuserDrowdown}>
          <div className="user__portfolio">
            <div className="user__portfolio-thumb">
              <Avatar
                name={user?.first_name + ' ' + user?.last_name}
                round={true}
                size="50"
                textSizeRatio={3}
              />
            </div>

            <div className="user__content">
              <span>
                {user?.first_name} {user?.last_name}
              </span>
            </div>
          </div>
        </button>
        <div className={`user__dropdown ${userDropdown ? 'user-enable' : ' '}`}>
          <ul>
            <li>
              <Link to={routes.profile}>
                <AccountBoxIcon /> Профил{' '}
              </Link>
            </li>
            <li>
              <Link to={routes.billing}>
                <CreditCardIcon />
                Плащания
              </Link>
            </li>
            <li>
              <button type="button" onClick={logout}>
                <LogoutIcon />
                Изход
              </button>{' '}
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

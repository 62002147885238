export function getByNestedKeys(obj: any, key: any) {
  if (key in obj) {
    return obj[key]
  }
  const keys = key.split('.')
  let value = obj
  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]]
    if (value === undefined) {
      break
    }
  }

  return value
}

export function removeByNestedKeys(data: any, objKey: any) {
  const tmpData = {...data}
  const keys = objKey.split('.')
  const finalKey = keys.pop()
  const node = keys.reduce((node: {[x: string]: {}}, key: string | number) => {
    return (node[key] ??= {})
  }, tmpData)
  node.splice(finalKey, 1)

  return tmpData
}

export const updateNestedJsonWithStringKeys = (
  data: any,
  objKey: any,
  value: any,
) => {
  const tmpData = {...data}
  const keys = objKey.split('.')
  const finalKey = keys.pop()
  const node = keys.reduce((node: {[x: string]: {}}, key: string | number) => {
    return (node[key] ??= {})
  }, tmpData)
  node[finalKey] = value

  return tmpData
}
